import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import FormView from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {makeUrlWithTableParams} from "../../../utils";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {

  state = {
    id: "",
    channel: '',
    owner: '',
    productType: 'contentCreator',
    prices: [],
    days: 30,
    active: true,
    channels: [],
    priceArs: 0,
    priceUsd: 0,
    hasShipments: false,
    shipments: 'none',
    addresses: [],
    channelShipments: [],
    giftable: false,
    translations: AvailableLanguages.map(language => ({
      language: language,
      description: "",
    })),
    hasStock: false,
    stock: 0,
  }

  onSubmitValidation = () => {
    if(!this.state.channel) {
      GlobalSnackbar.show({message: localization.get('must_complete_client'), type: GlobalSnackbarTypes.ERROR});
      return false
    }else {
      return true
    }
  }

  requestProduct = () =>
    request.products.get(this.props.match.params.id, "include=prices;translations;channel;channel.addresses;channel.channelShipments.prices;content;productShipments.address;productShipments.channelShipment.prices")
      .then(product => {
        const translations = AvailableLanguages.map(language => {
          const translation = product.translations.find(tr => tr.locale ===language)

          return {
            language: language,
            description: translation ? translation.description : "",
          };
        })


        this.setState({
          id: product.id,
          channel: product.channel,
          owner: {label: product.content.name || product.content.title, value: product.content.id},
          productType: product.content_type === "App\\ContentCreator" ? 'contentCreator' :  product.content_type === "App\\ContentCreation" ? 'contentCreation' : 'contentPack',
          price: product.price,
          days: product.days,
          currency: product.currency,
          active: !!product.active,
          giftable: !!product.is_giftable,
          translations: translations,
          hasStock: !!product.has_stock_control,
          stock: product.stock,
          priceArs: product.prices.find(price => price.currency === "ARS") ? product.prices.find(price => price.currency === "ARS").price : 0,
          priceUsd: product.prices.find(price => price.currency === "USD") ? product.prices.find(price => price.currency === "USD").price : 0,
          shipments: product.shipments || 'none',
          hasShipments: product.shipments && product.shipments != "none",
          addresses: product.productShipments ? product.productShipments.filter(ps => ps.address).map(ps => ps.address.id) : [],
          channelShipments: product.productShipments ? product.productShipments.filter(ps => ps.channelShipment).map(ps => ps.channelShipment.id): [],
        })
      })
      .catch(() => GlobalSnackbar.showGenericError)


  requestUpdate = () =>
    request.products.update(this.state.channel.id, this.props.match.params.id,
      {
        owner: this.state.owner.value,
        ownerType: this.state.productType,
        days: this.state.days,
        active: !!this.state.active,
        giftable: !!this.state.giftable,
        prices: [
          {
            price: this.state.priceArs,
            currency: "ARS"
          },
          {
            price: this.state.priceUsd,
            currency: "USD"
          }
        ],
        translations: this.state.translations,
        shipments: !this.state.hasShipments ? 'none' : this.state.shipments,
        addresses: (this.state.hasShipments && ['withdrawal', 'both'].includes(this.state.shipments)) ? this.state.addresses : [],
        channelShipments: (this.state.hasShipments && ['delivery', 'both'].includes(this.state.shipments)) ? this.state.channelShipments : [],
        stock: this.state.hasStock ? this.state.stock : null,
        hasStock: this.state.hasStock,
      })
      .then(() => GlobalSnackbar.show({
        message: localization.get('snackbar.entity_update_success'),
        type: GlobalSnackbarTypes.SUCCESS
      }))
      .catch(() => GlobalSnackbar.showGenericError)
      .finally(() => this.props.history.push(makeUrlWithTableParams('/panel/products')))


  onSubmitValidation = () => {
   if(!this.state.owner) {
      GlobalSnackbar.show({message: localization.get('must_complete_owner'), type: GlobalSnackbarTypes.ERROR});
      return false
    }
    if(!this.state.priceUsd || this.state.priceUsd == 0) {
      GlobalSnackbar.show({message: localization.get('product.must_complete_price'), type: GlobalSnackbarTypes.ERROR});
      return false
    }

    if (!this.state.priceArs || this.state.priceArs == 0 ) {
      GlobalSnackbar.show({message: localization.get('product.must_complete_price'), type: GlobalSnackbarTypes.ERROR});
      return false
    }
    return true
  }

  togglePresence = (target, id) => {
    let array = this.state[target];
    if (array.includes(id)) {
      array = array.filter(a => a != id);
    } else {
      array.push(id);
    }
    this.setState({[target]: array});
  }

  render = () => {
    const { id, disabled, channel, channels, owner, productType, priceArs, priceUsd, days, active, translations, shipments, hasShipments, addresses, channelShipments, giftable, hasStock, stock } = this.state

    return (
      <div>
        <FormViewContainer
          title={localization.get('form.update_product')}
          onBack={() => this.props.history.goBack()}
          initializationRequest={this.requestProduct}
          submitRequest={this.requestUpdate}
          validation={this.onSubmitValidation}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          <FormView
            id={id}
            channels={channels}
            channel={channel}
            owner={owner}
            productType={productType}
            priceArs={priceArs}
            priceUsd={priceUsd}
            days={days}
            onChange={e => this.setState({[e.target.id]: e.target.value})}
            onOwnerChange={owner => this.setState({ owner })}
            onTypeChange={type => this.setState({productType: type, owner: null })}
            disabled={disabled}
            active={active}
            onActiveChange={() => this.setState({active: !active})}
            translations={translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            shipments={shipments}
            onShipmentsChange={shipments => this.setState({shipments: shipments })}
            hasShipments={hasShipments}
            onHasShipmentsChange={() => this.setState({hasShipments: !hasShipments})}
            addresses={addresses}
            channelShipments={channelShipments}
            onAddressChange={(id) => this.togglePresence('addresses', id)}
            onChannelShipmentChange={(id) => this.togglePresence('channelShipments', id)}
            onGiftableChange={() => this.setState({giftable: !giftable})}
            giftable={giftable}
            hasStock={hasStock}
            stock={stock}
          />
        </FormViewContainer>
      </div>
    )
  }
}
