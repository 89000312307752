import React, {Component} from "react";
import {request} from "../../../../../api-client";
import Index from "../Form";
import {
  getActiveClient,
  homePathGenerator,
  ROLES,
  userCanCreateUserWithGeneralRole,
  userHasClientsInCommonWith,
  userisMultiClient,
  userisSingleClient,
  userIsSimpleMulticlient
} from "../../../../../api-client/core/authentication/utils";
import localization from "../../../../../config/localization"
import {makeImageUrlWithSize} from "../../../HomeElements/utils";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount = () => {
    this.requestUser();
  };

  userId = () => this.props.match.params.id;

  requestUser = () => {
    request.user.get(this.userId()).then(user => {
      if (userCanCreateUserWithGeneralRole(user.role.id)) {
        if (userisMultiClient() || userisSingleClient() || userIsSimpleMulticlient()) {
          if (userHasClientsInCommonWith(user)) {
            this.setState({ user, showForm: true });
          } else {
            this.props.history.push(homePathGenerator(`account/users`));
          }
        } else {
          this.setState({ user, showForm: true });
        }
      } else {
        this.props.history.push(homePathGenerator(`account/users`));
      }
    });
  };

  requestUpdate = data => {
    const client_roles = [
      {
        client_id: getActiveClient(),
        role_id: data.roleId,
        can_license:
          data.roleId === ROLES.supervisorId || data.roleId === ROLES.sysAdminId || data.roleId === ROLES.clientAdminId
            ? true
            : data.roleId === ROLES.userId
            ? data.canLicense
            : false,
      },
    ];
    return request.user.update({
      userId: this.userId(),
      name: data.name,
      email: data.email,
      roleId: this.state.user.role.id,
      imageFile: data.image.file,
      language: data.language,
      client_roles,
    });
  };

  onImageChange = image => this.setState({ image });

  render = () => {
    if (!this.state.user) {
      return null;
    }

    const { name, email, clientRoles, role, image, language } = this.state.user;

    const clientRole = clientRoles.find(x => x.client.id == getActiveClient());

    let roleId,
      canLicense = false;

    if (clientRole) {
      roleId = clientRole.role.id;
      canLicense = clientRole.can_license;
    }

    return (
      <Index
        {...this.props}
        name={name}
        email={email}
        roleId={roleId}
        language={language}
        canLicense={canLicense}
        image={image && makeImageUrlWithSize(this.state.user, 'md')}
        submitRequest={this.requestUpdate}
        formTitle={localization.get('account.update_user')}
        type={"update"}
      />
    );
  };
}
