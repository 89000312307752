import React, {Component} from "react";
import {request} from "../../../api-client";
import { makeUrlWithTableParams} from "../../../utils";
import Form from "../Form";
import {
  getStoredUser,
  ROLES,
  userCanCreateUserWithGeneralRole,
  userHasClientsInCommonWith,
  userisMultiClient,
  userisSingleClient,
  userIsSupervisor,
  userIsSysAdmin,
} from "../../../api-client/core/authentication/utils";
import localization from '../../../config/localization'
import {makeImageUrlWithSize} from "../../../layouts/Home/HomeElements/utils";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      user: null,
    };
  }

  componentDidMount = () => {
    this.requestUser();
  };

  userId = () => this.props.match.params.id;

  requestUser = () =>
    request.user.get(this.userId()).then(user => {
      if (userCanCreateUserWithGeneralRole(user.role.id)) {
        if (userisSingleClient()) {
          if (userHasClientsInCommonWith(user)) {
            this.setState({ user, showForm: true });
          } else {
            this.props.history.push(makeUrlWithTableParams("/panel/users"));
          }
        } else {
          this.setState({ user, showForm: true });
        }
      } else {
        this.props.history.push(makeUrlWithTableParams("/panel/users"));
      }
    });

  requestUpdate = data => {
    const requestData = {
      userId: this.userId(),
      name: data.name,
      email: data.email,
      description: data.description,
      imageFile: data.image.file,
      roleId: data.roleId,
      language: data.language,
      selectedArtists: data.selectedArtists.map(x => x.value),
      clearClouds: data.selectedClouds.length == 0,
      selectedClouds: data.selectedClouds.map(x => x.value),
      canCreateClients: data.canCreateClients,
      subscribedToPitches: data.subscribedToPitches,
      canSeeLicenses: data.canSeeLicenses,
      canCreateContentCreators: data.canCreateContentCreators,
      selectedCatalogs: data.selectedCatalogs.map(x => x.value),
      canEditAdmins: data.canEditAdmins,
      selectedChannels: data.selectedChannels,
      clientForEditor: data.clientForEditor && data.clientForEditor.value,
      gcbaId: data.gcbaId,
      mibaId: data.mibaId,
      address: data.address || null,
      canAccessMusic: data.canAccessMusic,
      canAccessClients: data.canAccessClients,
      canAccessRanking: data.canAccessRanking,
      canAccessConfig: data.canAccessConfig,
      canAccessLicense: data.canAccessLicense,
      canAccessReports: data.canAccessReports,
      brand: data.selectedBrand && data.selectedBrand.value,
      identityNumber: data.identityNumber,
      manageableChannels: data.manageableChannels.map(x => x.value),
    };

    const dataRepresentsToRequestClients = (represents, isAdmin) =>
      represents.map(represented => ({
        client_id: represented.client_id,
        role_id: represented.role_id,
        can_license: represented.can_license,
        access_level: isAdmin ? 5 : represented.access_level,
        allow_edit: isAdmin ? 1 : represented.allow_edit,
      }));

    switch (data.roleId) {
      case ROLES.MULTI_CLIENT:
        requestData.client_roles = dataRepresentsToRequestClients(data.represents);
        break;
      case ROLES.SIMPLE_MULTICLIENT:
        requestData.client_roles = dataRepresentsToRequestClients(data.represents);
        break;
      case ROLES.SINGLE_CLIENT:
        requestData.client_roles = [
          {
            client_id: data.client.value,
            role_id: data.roleInClient,
            can_license: data.roleInClient === ROLES.clientAdminId ? 1 : data.canLicense,
            access_level: data.roleInClient === ROLES.clientAdminId ? 5 : data.accessLevel,
            allow_edit:  data.roleInClient === ROLES.clientAdminId ? 1 : data.canEditContentCreators
          },
        ];
        break;
      case ROLES.contentSupervisorId:
        requestData.client_roles = [
          {
            client_id: data.clientForEditor && data.clientForEditor.value,
            role_id: ROLES.USER,
            can_license: 0,
            access_level: data.accessLevel,
            allow_edit:  1,
          },
        ];
        break;
    }

    return request.user.update(requestData);
  };

  onImageChange = image => this.setState({ image });

  render = () => {

    if (!this.state.showForm) {
      return null;
    }

    const {
      address, name, email, description, clientRoles, role, image, artists, language, can_create_clients,
      can_see_licenses, can_create_content_creators, clouds, catalogs, can_edit_admins, channels, providers,
      can_access_music, can_access_clients, can_access_ranking, can_access_config, can_access_license, can_access_reports, brand,
      identity_number, manageableChannels
    } = this.state.user;

    let client, roleInClient = '', canLicense = false, accessLevel= "0", represents = [], canEditContentCreators= false;
    let clientForEditor = this.state.user.clientForEditor ? {value: this.state.user.clientForEditor.id, label: this.state.user.clientForEditor.name, hasAccessLevel: !!this.state.user.clientForEditor.has_access_level} : null;

    if ([ROLES.SINGLE_CLIENT, ROLES.MUSIC_SUPERVISOR, ROLES.CONTENT_SUPERVISOR].includes(role.id) && clientRoles.length > 0 && clientRoles[0].client) {
      client = {value: clientRoles[0].client.id, label: clientRoles[0].client.name, hasAccessLevel: clientRoles[0].client.has_access_level };
      canLicense = clientRoles[0].can_license;
      roleInClient = clientRoles[0].role.id;
      accessLevel = clientRoles[0].access_level.toString();
      canEditContentCreators = !!clientRoles[0].allow_edit;

      if (ROLES.CONTENT_SUPERVISOR == role.id) clientForEditor = client
    }

    if (role.id === ROLES.MULTI_CLIENT || role.id === ROLES.SIMPLE_MULTICLIENT) {
      represents = clientRoles
        // Filtra a los clientes que tienen en comun el usuario propio y el objetivo
        // Mapea los client roles del usuario a unos que pueda interpretar el formulario
        .map(cr => ({
          role_id: cr.role.id,
          client_id: cr.client.id,
          can_license: cr.can_license,
          access_level: cr.access_level,
          allow_edit: cr.allow_edit,
          name: cr.client.name,
          hasAccessLevel: cr.client.has_access_level,
          canEdit: userIsSupervisor() || userIsSysAdmin() || (userisMultiClient() && getStoredUser().clientRoles.some(x => x.client.id == cr.client.id))
        }));
    }

    return (
      <Form
        {...this.props}
        name={name}
        email={email}
        description={description}
        roleId={role && role.id}
        client={client}
        selectedArtists={artists.map(artist => ({value: artist.id, label: artist.name}))}
        selectedClouds={clouds.map(cloud => ({value: cloud.id, label: cloud.name}))}
        selectedCatalogs={catalogs && catalogs.map(catalog => ({value: catalog.id, label: `${catalog.name} (${localization.get(catalog.visibility)})`}))}
        language={language}
        represents={represents}
        canLicense={canLicense}
        accessLevel={accessLevel}
        canCreateClients={can_create_clients}
        canCreateContentCreators={can_create_content_creators}
        image={image && makeImageUrlWithSize(this.state.user, 'md')}
        submitRequest={this.requestUpdate}
        formTitle={localization.get('user.update_user')}
        roleInClient={roleInClient}
        canSeeLicenses={can_see_licenses}
        type={"update"}
        canEditAdmins={can_edit_admins}
        channels={channels}
        clientForEditor={clientForEditor}
        providers={providers}
        address={address}
        canAccessMusic={can_access_music}
        canAccessClients={can_access_clients}
        canAccessRanking={can_access_ranking}
        canAccessConfig={can_access_config}
        canAccessLicense={can_access_license}
        canAccessReports={can_access_reports}
        brand={brand? {value: brand.id, label: brand.name}: null}
        canEditContentCreators={canEditContentCreators}
        identityNumber={identity_number}
        manageableChannels={manageableChannels}
      />
    );
  };
}
