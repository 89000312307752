import getAllForClient from './getAllForClient'
import show from './show'
import update from './update'
import removePhoto from './removePhoto'
import getAllPhotosForClient from './getAllPhotosForClient'
import getFilters from './getFilters'

export default host => ({
  getAllForClient: getAllForClient(host),
  show: show(host),
  update: update(host),
  removePhoto: removePhoto(host),
  getAllPhotosForClient: getAllPhotosForClient(host),
  getFilters: getFilters(host)
})
