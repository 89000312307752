import localization from "../../config/localization";
import {Checkbox, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select} from "@material-ui/core";
import {oneToTen, oneToX} from "../../utils";
import React from "react";
import ColorPicker from "../../components/Letflow/ColorPicker";

const HomeElementStyleSelection = (props) => {
  return (
    <>
      {["grid_featured", "featured"].includes(props.type) &&
        <>
          <span>{localization.get('home_editor.style')}</span>
          <RadioGroup
            name="featuredType"
            value={props.featuredType}
            onChange={(e) => {
              props.onChange(e);
              if (["movie","movie_alternative"].includes(e.target.value)) props.onChange({target: {value: "x1", name: "shape"}});
            }}
            style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row", width: "100%"}}
          >
            <FormControlLabel value="default" control={<Radio color="default"/>} label={localization.get('default')}/>
            {props.shape != 'x3' && <FormControlLabel value="inverted" control={<Radio color="default"/>} label={localization.get('home_editor.inverted')}/>}
            {props.shape != 'x3' && <FormControlLabel value="editorial" control={<Radio color="default"/>} label={localization.get('home_editor.editorial_up')}/>}
            {props.shape != 'x3' && <FormControlLabel value="editorial_inverted" control={<Radio color="default"/>} label={localization.get('home_editor.editorial_inverted')}/>}
            {props.type != 'grid_featured' && <FormControlLabel value="movie" control={<Radio color="default"/>} label={localization.get('form.movieView')}/>}
            {props.type != 'grid_featured' && <FormControlLabel value="movie_alternative" control={<Radio color="default"/>} label={localization.get('form.movieView.alternative')}/>}
            <FormControlLabel value="news" control={<Radio color="default"/>} label={localization.get('home_editor.news')}/>
          </RadioGroup>
        </>
      }
       {! ["grid"].includes(props.type) &&
          <FormControlLabel
            style={{width: "100%"}}
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={props.mobileGrid}
                onChange={() => props.onCheckboxChange('mobileGrid')}
              />
            }
            label={localization.get('home_editor.mobile_grid')}
          />
        }

      {(!["grid_featured", 'unique'].includes(props.type) && !["movie","movie_alternative"].includes(props.featuredType)) &&
        <>
          <span>{localization.get('home_editor.format')}</span>
          <div style={{display: "flex"}}>
            <RadioGroup
              name="shape"
              value={props.shape}
              onChange={props.onChange}
              style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
            >
              {props.type !== 'featured' && <FormControlLabel value="square" control={<Radio color="default"/>}
                                                              label={localization.get('shape.square')}/>}
              {props.type !== 'featured' && <FormControlLabel value="round" control={<Radio color="default"/>}
                                                              label={localization.get('shape.round')}/>}
              {props.type !== 'featured' && props.isNavbar &&
                <FormControlLabel value="movie" control={<Radio color="default"/>}
                                  label={localization.get('form.movieView')}/>}
              {props.type !== 'featured' && props.isNavbar &&
                <FormControlLabel value="movie_alternative" control={<Radio color="default"/>}
                                  label={localization.get('form.movieView.alternative')}/>}
              {props.type !== 'featured' &&
                <FormControlLabel value="16:9" control={<Radio color="default"/>} label={localization.get('16:9')}/>}
              {props.type !== 'featured' && <FormControlLabel value="rectangle" control={<Radio color="default"/>}
                                                              label={localization.get('shape.rectangle')}/>}
              {props.type === 'featured' && (props.featuredType !== 'news' || !(['left', 'right'].includes(props.textPosition))) &&
                <FormControlLabel value="x4" control={<Radio color="default"/>} label={localization.get('shape.x4')}/>}
              {props.type === 'featured' && (props.featuredType === 'default' || (props.featuredType === 'news' && !(['left', 'right'].includes(props.textPosition)))) &&
                <FormControlLabel value="x3" control={<Radio color="default"/>} label={localization.get('shape.x3')}/>}
              {props.type === 'featured' && (props.featuredType !== 'news' || !(['left', 'right'].includes(props.textPosition))) &&
                <FormControlLabel value="x2" control={<Radio color="default"/>} label={localization.get('shape.x2')}/>}
              {(props.type === 'featured' && (props.featuredType !== 'news' || !(['default', 'inverted'].includes(props.textPosition)))) &&
                <FormControlLabel value="x1" control={<Radio color="default"/>} label={localization.get('shape.x1')}/>}
              {props.type === 'custom' && <FormControlLabel value="movie" control={<Radio color="default"/>} label={localization.get('form.movieView')}/>}
              {["carousel", "grid"].includes(props.type) && <FormControlLabel value="rectangle_event" control={<Radio color="default"/>} label={localization.get('shape.rectangle_event')}/>}
            </RadioGroup>
            
            {(props.shape !== "movie" || !["movie", "movie_alternative"].includes(props.featuredType) ) &&
              <>
                {(props.shape === 'square' && props.type === 'grid') &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        disabled={props.withText}
                        checked={props.fixedTitle}
                        onChange={() => props.onCheckboxChange('fixedTitle')}
                      />
                    }
                    label={localization.get('home_editor.grid.fixed_title')}
                  />
                }
                {props.shape === 'round' && props.type !== 'carousel' &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.withBackground}
                        onChange={() => props.onCheckboxChange('withBackground')}
                      />
                    }
                    label={localization.get('home_editor.grid.background')}
                  />
                }
                {((props.shape === '16:9' && props.type !== 'grid') || props.shape === 'x4') && props.featuredType !== 'news' &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.numbered}
                        onChange={() => props.onCheckboxChange('numbered')}
                      />
                    }
                    label={localization.get('home_editor.numbered')}
                  />
                }

                {props.shape === 'x4' && props.featuredType !== 'news' &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.square}
                        onChange={() => props.onCheckboxChange('square')}
                      />
                    }
                    label={localization.get('home_editor.square')}
                  />
                }


                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={props.hasMargin}
                      onChange={() => props.onCheckboxChange('hasMargin')}
                    />
                  }
                  label={localization.get('home_editor.has_margin')}
                />

                {(props.featuredType === 'news' && props.shape !== 'x1') &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.showAuthor}
                        onChange={() => props.onCheckboxChange('showAuthor')}
                      />
                    }
                    label={localization.get('home_editor.show_author')}
                  />
                }
                {(props.shape === 'rectangle' || (props.shape === '16:9' && props.type === "grid") || (props.shape === 'square' && props.type === 'carousel')) && props.featuredType !== 'news' &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.onlyImage}
                        onChange={() => props.onCheckboxChange('onlyImage')}
                      />
                    }
                    label={localization.get('home_editor.only_image')}
                  />
                }
                {props.shape !== 'round' && !(props.shape === '16:9' && props.type === 'grid') && props.featuredType !== 'news' &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.rounded}
                        disabled={props.withText && props.shape === 'square' && props.type === 'grid'}
                        onChange={() => props.onCheckboxChange('rounded')}
                      />
                    }
                    label={localization.get('home_editor.rounded_borders')}
                  />
                }
                {props.shape === 'square' && props.type === 'grid' &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={props.withText}
                        disabled={props.fixedTitle || props.rounded}
                        onChange={() => props.onCheckboxChange('withText')}
                      />
                    }
                    label={localization.get('home_editor.with_text')}
                  />
                }
              </>
            }
          </div>
        </>
      }
      {props.shape == "rectangle_event" &&
          <>
            <span style={{marginBottom: 15}}>{localization.get('home_editor.pill_colors')}</span>
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{marginRight: 10}}>
                <span style={{marginBottom: 15}}>{localization.get('home_editor.background_color')}</span>
                <ColorPicker
                  id={'pillBackgroundColor'}
                  handleColorChange={color =>
                    props.onChange({
                      target: {
                        id: "pillBackgroundColor",
                        value: color
                      }
                    })
                  }
                  startColor={props.pillBackgroundColor}
                  disableAlpha={true}
                />
              </div>
              <div>
                <span style={{marginBottom: 15}}>{localization.get('home_editor.font_color')}</span>
                <ColorPicker
                  id={'pillFontColor'}
                  handleColorChange={color =>
                    props.onChange({
                      target: {
                        id: "pillFontColor",
                        value: color
                      }
                    })
                  }
                  startColor={props.pillFontColor}
                  disableAlpha={true}
                />
              </div>
            </div>
          </>
        }

      {((["grid_featured", "featured"].includes(props.type) && props.featuredType === 'news' && !!props.shape) || (props.type === 'carousel' && ['rectangle', '16:9'].includes(props.shape))) &&
        (props.shape === 'x1' ?
          <>
            <span>{localization.get('home_editor.text_position_desktop')}</span>
            <div style={{display: "flex"}}>
              <RadioGroup
                name="textPosition"
                value={props.textPosition}
                onChange={props.onChange}
                style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row", width: "100%"}}
              >
                <FormControlLabel value="left" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.left')}/>
                <FormControlLabel value="right" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.right')}/>
              </RadioGroup>
            </div>
            <span>{localization.get('home_editor.text_position_mobile')}</span>
            <div style={{display: "flex"}}>
              <RadioGroup
                name="textPositionMobile"
                value={"bottom"}
                style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row", width: "100%"}}
              >
                <FormControlLabel value="bottom" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.bottom')}/>
              </RadioGroup>
            </div>
          </>
        : (props.type === 'carousel' && ['rectangle', '16:9'].includes(props.shape)) ? 
        <>
          <span>{localization.get('home_editor.text_position')}</span>
          <div style={{display: "flex"}}>
            <RadioGroup
              name="textPosition"
              value={props.textPosition}
              onChange={props.onChange}
              style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row", width: "100%"}}
            >
              <FormControlLabel value="bottom" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.bottom')}/>
              <FormControlLabel value="top" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.top')}/> 
            </RadioGroup>
          </div>
        </>
        :
        <>
          <span>{localization.get('home_editor.title_position')}</span>
          <div style={{display: "flex"}}>
            <RadioGroup
              name="textPosition"
              value={props.textPosition}
              onChange={props.onChange}
              style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row", width: "100%"}}
            >
              <FormControlLabel value="default" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.default')}/>
              <FormControlLabel value="inverted" control={<Radio color="default"/>} label={localization.get('home_editor.text_position.inverted')}/>
            </RadioGroup>
          </div>
        </>
      )}

      {props.shape === 'x1' && ["grid_featured", "featured"].includes(props.type) && props.featuredType !== "news" && props.featuredType !== "movie" &&
        <div style={{position: 'relative', float: "left", padding: "10px 0px", width: "100%", display: "flex", flexDirection: "column"}}>
          <span>{localization.get('home_editor.height.label')}</span>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={props.halfHeight}
                    onChange={() => props.onCheckboxChange('halfHeight')}
                  />
                }
                label={localization.get('home_editor.height.half')}
              />
            <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={props.mobileHalfHeight}
                    onChange={() => props.onCheckboxChange('mobileHalfHeight')}
                  />
                }
                label={localization.get('home_editor.height.mobile_half')}
              />
          </div>
        </div>
      }
      {["grid_featured", "featured"].includes(props.type) && props.shape === 'x4' && props.featuredType == 'news' && 
        <div style={{position: 'relative', float: "left", padding: "10px 0px", width: "100%", display: "flex", flexDirection: "column"}}>
          <span>{localization.get('home_editor.image_style')}</span>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={props.useFeaturedFormat}
                  onChange={() => props.onCheckboxChange('useFeaturedFormat')}
                />
              }
              label={localization.get('home_editor.use_featured_format')}
            />
          </div>
        </div>
      }
       {["grid_featured", "featured"].includes(props.type) && props.featuredType === 'news' &&
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={props.useRegular}
                onChange={() => props.onCheckboxChange('useRegular')}
              />
            }
            label={localization.get('home_editor.regular')}
          />
        }
      {props.shape !== 'movie' &&
        <div style={{position: 'relative', float: "left", padding: "10px 0px", width: "100%"}}>
          <div style={{position: 'relative', float: "left", width: "100%"}}>
            <span>{localization.get('home_editor.background_style')}</span>
            <RadioGroup
              name="backgroundStyle"
              value={props.backgroundStyle}
              onChange={props.onChange}
              style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row", width: "100%"}}
            >
              <FormControlLabel value="default" control={<Radio color="default"/>}
                                label={localization.get('home_editor.default')}/>
              <FormControlLabel value="50" control={<Radio color="default"/>} label={"Color 50%"}/>
              <FormControlLabel value="100" control={<Radio color="default"/>} label={"Color 100%"}/>
            </RadioGroup>
          </div>
          {props.backgroundStyle !== "default" &&
            <>
              <span style={{marginBottom: 15}}>{localization.get('home_editor.background_color')}</span>
              <ColorPicker
                id={'backgroundColor'}
                handleColorChange={color =>
                  props.onChange({
                    target: {
                      id: "backgroundColor",
                      value: color
                    }
                  })
                }
                startColor={props.backgroundColor}
                disableAlpha={true}
              />
            </>
          }
        </div>
      }

      {props.type !== 'unique' && ((["grid_featured", "grid"].includes(props.type) && props.shape !== 'movie' ) || (props.type === 'carousel' && (props.shape === 'round' || props.shape === '16:9'))) &&
        <div style={{float: "left", position: "relative"}}>
          <div style={{float: "left", position: "relative", width: 80, margin: "15px 0"}}>
            <Select
              value={props.columns}
              name="columns"
              onChange={props.onChange}
            >
              {[2,4,6].map(i => <MenuItem value={i}>{i}</MenuItem>)}
            </Select>
            <FormHelperText>{localization.get('home_editor.columns')}</FormHelperText>
          </div>
          {["grid_featured", "grid"].includes(props.type) &&
          <div style={{float: "left", position: "relative", width: 50, margin: "15px 0"}}>
            <Select
              name="rowsToShow"
              value={props.rowsToShow}
              onChange={props.onChange}
              style={{width: "inherit"}}
            >
              {oneToTen().map(i => <MenuItem value={i}>{i}</MenuItem>)}
            </Select>
            <FormHelperText>{localization.get('home_editor.rows_to_show')}</FormHelperText>
          </div>
          }
          {["grid_featured", "grid"].includes(props.type) && 
            <div style={{float: "left", position: "relative", width: 70, margin: "15px 0px 15px 30px"}}>
              <Select
                name="rowsToShowMobile"
                value={props.rowsToShowMobile}
                onChange={props.onChange}
                style={{width: "inherit"}}
              >
                {oneToX(12).map(i => <MenuItem value={i}>{i}</MenuItem>)}
              </Select>
              <FormHelperText>{localization.get('home_editor.rows_to_show_mobile')}</FormHelperText>
            </div>
          }
        </div>
      }

      {["carousel", "featured"].includes(props.type) &&
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={props.useDots}
              onChange={() => props.onCheckboxChange('useDots')}
            />
          }
          label={localization.get('home_editor.use_dots')}
        />
      }
    </>
  )
}

export default HomeElementStyleSelection
