import React from "react";
import EventManager, {events} from "../../../utils/EventManager";
import {request} from "../../../api-client";
import PlaylistImage from "../../../assets/img/Letflow/playlist.jpg";
import SavedQueryImage from "../../../assets/img/Letflow/savedQuery.jpg";
import FavoritesImage from "../../../assets/img/Letflow/favorite.jpg";
import RecentsImage from "../../../assets/img/Letflow/recents.jpg";
import ProjectsImage from "../../../assets/img/Letflow/projects.jpg";
import NoImage from "../../../assets/img/Letflow/no-image.jpg";
import {Tooltip, ClickAwayListener} from "@material-ui/core";
import HomeElementItemState from "./HomeElementItemStateX4";
import {Link} from "react-router-dom";
import {
  channelCanAddToFavorites, generateLink,
  getActiveChannel,
  getActiveClient, getUseTools, getStoredChannel, getStoredUser,
  homePathGenerator, getMainTranslation,
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import styleRectangle from "./Styles/Carousel/documentary.module.css"
import eventRectangle from "./Styles/Carousel/event.module.css"
import styleRectangleInverted from "./Styles/Carousel/documentaryInverted.module.css"
import styleSquare from "./Styles/Carousel/squareDescription.module.css"
import font from "./Styles/Fonts/fonts.module.css"
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import {ReactComponent as Heart} from "../../../components/Letflow/SvgIcons/heart.svg"
import {ReactComponent as Share} from "../../../components/Letflow/SvgIcons/share.svg"
import {ReactComponent as Person} from "../../../components/Letflow/SvgIcons/person.svg"
import {ReactComponent as Group} from "../../../components/Letflow/SvgIcons/groups.svg";
import {convertFromRaw, EditorState} from "draft-js";
import {makeCustomClick, makeCustomLink} from "./utils";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import st from "../HomeNew.module.css";
import {Cancel} from "@material-ui/icons";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { YouTubeEmbed } from 'react-social-media-embed';
import {get} from "lodash"
import moment from "moment";
import { getSplittedDate, getSameDaySchedule } from "./utils";
import EventDatesInfo from "../../../components/Letflow/EventDatesInfo";
import AlternativeEventsInfo from "../../../components/Letflow/EventDatesInfo/AlterantiveEventsInfo";

export const makeImageCarousel = (element, imgSize = {width: 400, height: 400, fit: false}, useMobile=false, useFeatured=false) => {
  let image;

  if (useMobile || useFeatured) {
    if (useFeatured && element.featuredImage) {
      let file = element.featuredImage.original_name.split('.');
      image = request.file.image.makeCroppedUrl(element.featuredImage.id, imgSize.width, imgSize.height, file[0], file.length > 1 ? file.pop() : 'jpg');
      return image;
    }
    if (useMobile && element.mobileImage) {
      let file = element.mobileImage.original_name.split('.');
      image = request.file.image.makeCroppedUrl(element.mobileImage.id, 400, 400, file[0], file.length > 1 ? file.pop() : 'jpg');
      return image;
    }
  }
  if (element.kind === "playlist") {
    if (element.image) {
      image = request.file.image.makeCroppedUrl(element.image.id, imgSize.width, imgSize.height);
    } else if (element.featuredImage && element.featuredImage.image) {
      image = request.file.image.makeCroppedUrl(
        element.featuredImage.image.id,
        imgSize.width,
        imgSize.height
      );
    } else {
      image = PlaylistImage;
    }
  } else if (element.kind === "savedQuery") {
    if (element.featuredImage && element.featuredImage.image) {
      image = request.file.image.makeCroppedUrl(
        element.featuredImage.image.id,
        imgSize.width,
        imgSize.height
      );
    } else {
      image = SavedQueryImage;
    }
  } else if (element.kind === "favorites") {
    if (element.image) {
      image = request.file.image.makeCroppedUrl(element.image.id, imgSize.width, imgSize.height);
    } else {
      image = FavoritesImage;
    }
  } else if (element.kind === "recents") {
    if (element.image) {
      image = request.file.image.makeCroppedUrl(element.image.id, imgSize.width, imgSize.height);
    } else {
      image = RecentsImage;
    }
  } else if (element.kind === "production") {
    if (element.featuredImage && element.featuredImage.image) {
      image = request.file.image.makeCroppedUrl(
        element.featuredImage.image.id,
        400,
        400
      );
    } else {
      image = ProjectsImage;
    }
  }
  else if (element.image) {
    image = request.file.image[imgSize.fit ? 'makeFittedUrl' : 'makeCroppedUrl'](element.image.id, imgSize.width, imgSize.height)
  } else {
    image = NoImage;
  }
  return image;
};

class HomeElementCarouselItem extends React.Component {
  constructor(props) {
    super(props);
    moment.locale(localization.getLanguage());
    this.state = {
      homeElement: this.props.homeElement,
      showShareDialog: false,
      showModal: false,
      limitReached: false,
      shareUrl: null,
      catchedClick: false,
      over: false, // Añadir estado para hover
    };
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
    this.checkViewsLimit()
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
  }

  checkViewsLimit = () => {
    if (this.props.data.view_limit > 0 && this.props.data.views >= this.props.data.view_limit) {
      this.setState({limitReached: true})
    }
  }

  addToFavoriteItemDescription = ({id, type}) => {
    this.addToFavorites({id, type})
  }

  removeFromFavoritesItemDescription = ({id, type}) => {
    this.removeFromFavorites({id, type})
  }

  addToFavorites = ({ id, type }) => {
    if (this.props.data.id === id && this.props.data.kind === type) {
      let items = this.state.homeElement.items.data.map(item => {
        if (item.id === id) {
          item.favorite = true;
        }
        return item;
      });
      !!getStoredUser() &&  this.setState({
        homeElement: { ...this.state.homeElement, items: { data: items } }
      });

      EventManager.getInstance().dispatch(events.ADD_TO_FAVORITE_BUTTON_PRESS, {id, type});
    }
  };

  removeFromFavorites = ({ id, type }) => {
    if (this.props.data.id === id && this.props.data.kind === type) {
      let items = this.state.homeElement.items.data.map(item => {
        if (item.id === id) {
          item.favorite = false;
        }
        return item;
      });

      this.setState({
        homeElement: { ...this.state.homeElement, items: { data: items } }
      });

      EventManager.getInstance().dispatch(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, {id, type});
    }
  };

  renderShareDialog = () => {
    if (this.state.shareUrl) {
      return (<ShareOnSocials
        show={this.state.showDialog}
        handleClose={() => this.setState({showDialog: false, shareUrl: null})}
        url={this.state.shareUrl}
        darkMode={true}
      />);
    }
    return (<ShareDialog
      show={this.state.showShareDialog}
      handleClose={() => this.setState({showShareDialog: false})}
      entity={'playlist'}
      id={this.state.playlistToShareId}
    />);
  }

  sharePlaylist = (playlistId) => {
    if (getUseTools()) {
      return request.playlist.shareByChannel(playlistId)
        .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
        .catch(GlobalSnackbar.showGenericError)
    }else {
      request.playlist.share(playlistId, null, getActiveClient())
        .then(response => this.setState({
          showDialog: true, 
          shareUrl: `${window.location.protocol}//${window.location.hostname}/public/playlists/${response.share_token}`
        }))
        .catch(GlobalSnackbar.showGenericError)
    }
  }

  toggleModalFavorite = (data) => {
    let items = this.state.homeElement.items.data.map(item => {
      if (item.id === data.id) {
        item.favorite = !item.favorite;
      }
      return item;
    });

    this.setState({
      homeElement: { ...this.state.homeElement, items: { data: items } }
    });
  }

  openContentCreatorModal = () => EventManager.getInstance().dispatch(
      events.OPEN_CONTENT_MODAL,
      {
        id: this.props.data.kind === 'video_content_creation' ? this.props.data.content_creator_id : this.props.data.id,
        dataType: "contentCreator",
        dataName: this.props.data.kind === 'video_content_creation' ? '' : this.props.data.name,
        origin: this.props.home ? (this.props.homeElement ? getMainTranslation(this.props.homeElement, "title", true): "") : this.props.search ? 'Search' : this.props.realated ? 'Related' : '',
        onTagChange: this.props.handleNewSearch || false,
      });

  openPlaylistModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
      id: this.props.data.id,
      dataType: "playlist",
      dataName: this.props.data.name || this.props.data.title,
    });
  }


  makeFavoriteButton = (data, favorite, icon) => {
    if (!this.state.limitReached) {
      let onClick = ''
      const trackingName = getMainTranslation(data, "name")
      if (favorite) {
        onClick = (e) => {
          e.stopPropagation()
          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: "Remove"
          });
          this.removeFromFavorites({id: data.id, type: this.props.data.kind})
        }
      } else {
        onClick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: "Add"
          });
          // GAevent('Contenido', "Favorite", data.name || data.title)
          this.addToFavorites({id: data.id, type: this.props.data.kind})
        }
      }

      switch (icon) {
        case 'star':
          return <Star onClick={onClick}/>
        case 'heart':
          return <Heart onClick={onClick}/>
        case 'list':
          return <List onClick={onClick}/>
      }
    }
  }

  getBorders = () => {
    let  border = "0px"
    if (this.props.rounded) {
      switch (this.props.shape) {
        case 'square':
          border = "50px"
          break;
        case 'rectangle':
          border = "50px"
          break;
        case '16:9':
          border = "20px"
          break;
        case 'round':
          border = "50%"
          break;
      }
    } else if (this.props.shape === "round") {
      border = "50%"
    }

    return border
  }

  makeEditorialDescription = (data) => {
    let description = EditorState.createWithContent(convertFromRaw(JSON.parse(data.shortDescription)))
    return description.getCurrentContent().getPlainText()
  }

  getStoredChannelContainerTitleWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_title) {
      switch(channel.preferences.container_title.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default-text";
  }

  getStoredChannelContainerDescriptionWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_description) {
      switch(channel.preferences.container_description.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default_text";
  }

  handleMouseEnter = () => {
    this.setState({ over: true });
  }

  handleMouseLeave = () => {
    this.setState({ over: false });
  }

  render = () => {
    const { data, handleElementClick } = this.props;
    const { over } = this.state; // Obtener el estado de hover

    let type;

    if (data.client_type) {
      type = "speaker";
    } else if (["external", "internal"].includes(data.type)) {
      type = "artist";
    } else if (data.kind === "playlist") {
      type = "playlist";
    } else if (data.kind === "favorites") {
      type = "favorites";
    } else if (data.kind === "recents") {
      type = "recents";
    } else if (data.kind === "savedQuery") {
      type = "savedQuery";
    } else if (data.kind === "album") {
      type = "album";
    } else if (data.kind === "production") {
      type = "production";
    } else {
      type = "other";
    }
  
    const eventView = this.props.shape == 'rectangle_event';
    const style = this.props.shape === 'rectangle' ? 
      ((this.props.textPosition && this.props.textPosition === 'top') ?
       styleRectangleInverted : styleRectangle) : eventView ? eventRectangle : styleSquare
    const imgSize = this.props.shape === 'rectangle' ? {width: 400, height: 500, fit: false} : {width: 400, height: 400, fit: false}
    const border = this.getBorders();
    const mustCatchClick = window.innerWidth < 650 && data && data.kind == 'content_creator';
    const { day, month, time } = getSplittedDate(data.date);
    const schedule = getSameDaySchedule(data.date, data.eventDates);

    if(this.props.data.data_source == 'embed') {
      return (
       <div
           className={`${style['items-content', 'embed-container']}`}
         >
           <YouTubeEmbed
             placeholderImageUrl={data.image? makeImageCarousel(data, imgSize) : null}
             url={get(this.props.data, "extra.link", "")}
             height={400}
             width={"100%"}
           />
       </div>
      );
   }
   
    return (
      <ClickAwayListener onClickAway={() => this.setState({catchedClick: false})}>
        <Link
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}  
          rel={(data.type === 'custom' && data.data_source == 'link') ? "nofollow" : "canonical"}
          to={data.type === 'custom' ? makeCustomLink(data): generateLink(data.kind, data.id, data.title || data.name, false)}
          className={`${style['items-content']} ${this.props.inverted && style['inverted']} ${this.props.hasMargin ? style['has-margin'] : ""}`}
          style={{borderRadius: border}}
          onClick={(e) => {
            if (!this.state.catchedClick && mustCatchClick) {
              this.setState({catchedClick: true})
              e.preventDefault();
              e.stopPropagation();
              return false;
            }else {
              this.setState({catchedClick: false})
            }

            if (data.type === 'custom') {
              makeCustomClick(e, data)
            }else if (!['artist', 'production', 'content_creator', 'playlist'].includes(data.kind)) {
              e.preventDefault()
              handleElementClick()
            } else if (data.kind === 'content_creator') {
              if (this.state.limitReached) {
                e.preventDefault();
                EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
              } else if (!getActiveChannel() || getStoredChannel().use_canonicals != "1"){
                e.preventDefault();
                this.openContentCreatorModal();
              }
            }else if (data.kind === 'playlist') {
              e.preventDefault();
              this.openPlaylistModal();
            }
          }}
        >
          {this.props.onRemoveFavorites &&
            <Cancel
              style={{
                position: "absolute",
                fontSize: "30px",
                top: 0,
                right: 0,
                zIndex: 10000,
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%"
              }}
              onClick={e => this.props.onRemoveFavorites(e, data.id)}
            />
          }
          {this.state.limitReached &&
          <Tooltip placement={"top"} title={localization.get('content.views_limit_reached')}>
            <Group className={style['limit-reached']}/>
          </Tooltip>
          }
          <div
            className={`${style['box']} ${border !== "0px" && style['radius']}`}
             style={{
               backgroundColor: getActiveChannel() ? getStoredChannel().preferences.element_background : "#F4F4F4",
               borderRadius: this.props.shape === 'rectangle' ? border : '0px',
            }}
          >
            <div
              className={style['photo-container']}
              rel="canonical"
              style={{ cursor: "pointer", borderRadius: border }}
            >
              <div
                className={style['photo']}
                style={{
                  backgroundImage: `url(${makeImageCarousel(data, imgSize, window.innerWidth < 650)}), url(${NoImage})`,
                  borderTopRightRadius: this.props.shape === 'rectangle' ? border : '0px',
                  borderTopLeftRadius: this.props.shape === 'rectangle' ? border : '0px',
                }}
              >
                {((type === "playlist" && (!data.created_by_admin || !data.image)) || type === "savedQuery" || type === "production") && (
                  <div
                    className={`featured-image-title ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                    style={getActiveChannel() ? {
                      fontSize: window.innerWidth > 650 ? getStoredChannel().preferences.container_title.size : "1.15rem",
                      color: getStoredChannel().preferences.container_title.color,
                    }: {
                      color: data.featuredImage
                        ? data.featuredImage.color
                        : "rgb(252, 205, 47)"
                    }}
                  >
                    {data.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")|| data.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                  </div>
                )}
                {schedule.length > 1 &&
                  <AlternativeEventsInfo eventDates={schedule} onlyTimes={true} show={this.state.over}/>
                }
              </div>
              <HomeElementItemState
                recentlyCreated={data.recentlyCreated}
                recentlyUpdated={data.recentlyUpdated}
                fullOpacity={this.state.over}
              />

              {(this.props.shape === 'rectangle' || this.props.shape === 'square') && data.additions && data.additions.split(';')[0] && !eventView &&
              <div className={`${style["extra"]}`}>
                <div
                  className={`${style["author"]} ${st[this.getStoredChannelContainerDescriptionWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontSize: window.innerWidth > 600 ? Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 1.05) : 12,
                  } : {}}
                >
                  {data.additions.split(';')[1] &&
                    <div className={`${style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`} >
                      {data.additions.split(';')[1]}
                    </div>
                  }
                  {data.additions.split(';')[0]}
                </div>
              </div>
              }
              {(eventView && (data.date || (data.is_event && data.when))) &&
                <div className={`${style["extra"]}`}>
                    {day && month ?
                      <p className={`${style["date"]} ${st[this.getStoredChannelContainerDescriptionWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>
                          <span className={`${style["date-number"]}`}>{day}</span>
                          <span>{month}</span>
                      </p>
                    : data.when ?
                      <div className={`${style["when"]}`}><span>{data.when}</span></div>
                      : null
                  }
                  <div className={`${style["hour-container"]}`}> 
                    {data.additions && data.additions.split(';')[1] && 
                      <div className={`${style["thematic-pill"]}`} style={{ color: this.props.pillColor || "black", backgroundColor: this.props.pillBackground || "white"}}>
                        {data.additions.split(';')[1]}
                      </div>
                    }
                    {schedule.length > 1 ?
                      <div className={`${style["hour"]}`}>
                        <EventDatesInfo eventDates={schedule} onlyTimes open={this.state.catchedClick} onlyChip={eventView}/>
                      </div>
                      : time ?
                      <div className={`${style["hour"]}`}>{time} hs</div>
                       : null
                    }            
                    
                  </div>
                </div>
              }
            </div>
            <div
              className={style['info']}
              style={getActiveChannel() ? {
                backgroundColor: getStoredChannel().preferences.element_background,
                borderRadius: this.props.shape === 'rectangle' ? border : '0px',
              }: {}}
            >
              <div
                className={`${style["title"]} ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                style={getActiveChannel() ? {
                 fontSize: Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 0.75),
                } : {}}
              >
                {data.title || data.name}
              </div>
              <div
                className={`${style["text"]} ${st[this.getStoredChannelContainerDescriptionWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                style={getActiveChannel() ? {
                 fontSize:  Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 0.75),
                }: {}}
              >
                {data.subtitle && <p>{data.subtitle}</p>}
                {data.artist ?
                  <Link
                    className="album-description-link"
                    to={homePathGenerator(`artist/${data.artist.id}`)}
                  >
                    {get(data, "artist.name", localization.get('compiled'))}
                  </Link>
                  : (data.shortDescription || data.synopsis) ?
                    <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{ __html:
                        (data.shortDescription || data.synopsis) ?
                          (data.kind === "content_creator" ?
                            (data.synopsis || "") :
                            data.shortDescription && data.shortDescription.replace(/<[^>]*>|&nbsp;/g, ' ')
                          ) : ""
                    }} />
                  :
                    <span>&nbsp;</span>
                }
              </div>
            </div>


              {!this.props.onRemoveFavorites && ['album', 'content_creator', 'artist', 'playlist', `${getActiveChannel() ? "" : 'video_content_creation'}`].includes(this.props.data.kind) &&
                data.type !== 'custom' &&
              <div className={style["icons"]} style={{right: schedule.length > 1 ? 120 : 20}}>
                {(!getActiveChannel() || getUseTools()) || (getActiveChannel() && channelCanAddToFavorites()) ?
                  <>
                    <div className={`${style['star']} ${data.favorite && style['active']}`}>
                      {this.makeFavoriteButton(data, data.favorite, !getActiveChannel() ? 'star' : getStoredChannel().preferences.fav_icon)}
                    </div>

                    {(!getActiveChannel() || getUseTools()) &&
                      <>
                        {data.kind === "content_creator" &&
                          <AddToPlaylist
                            clientId={getActiveClient()}
                            content='contentCreators'
                            item={data}
                            contentCreatorType={data.type.slug}
                            extraClass={style['list']}
                            tooltipTitle={localization.get("add_to_playlist")}
                          />
                        }
                        {data.kind === "video_content_creation" &&
                          <>
                            {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                              <AddToPlaylist 
                                clientId={getActiveClient()}
                                content='contentCreations'
                                item={data}
                                extraClass={style['list']}
                                tooltipTitle={localization.get("ranking_config.playlist_add")}
                              />
                            }
                            <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                              <div className={style['list']}>
                                <Person onClick={(e) => {
                                  e.stopPropagation();
                                  this.openContentCreatorModal()
                                }}/>
                              </div>
                            </Tooltip>
                          </>
                        }
                      </>
                    }

                      {data.kind === "playlist" &&
                      <Tooltip placement="bottom" title={localization.get("share")}>
                        <div className={style['list']}>
                        <Share onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.sharePlaylist(data.id);
                        }}/>
                        </div>
                      </Tooltip>
                      }

                  </>
                  :
                  <>
                    {data.kind === "video_content_creation" &&
                    <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                      <div className={style['list']}>
                        <Person onClick={(e) => {
                          e.stopPropagation();
                          this.openContentCreatorModal()
                        }}/>
                      </div>
                    </Tooltip>
                    }
                  </>
              }
              </div>
            }
          </div>
        </Link>
      {this.renderShareDialog()}
      </ClickAwayListener>
    );
  };
}

export default HomeElementCarouselItem;