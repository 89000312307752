import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";
import AsyncSelect from "react-select/lib/Async";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import PurchaseTable from "../Puchases/Table";
import ContentPacksTable from "../ContentPacks/index";
import ProductCodesTable from "../ProductCodes";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import { FileCopy } from "@material-ui/icons";

let table

class Table extends Component {
  state = {
    channel: '',
    containerType: 'all',
    copied: localization.get('copy_link'),
    loaded: true,
    viewType: "products"
  };

  componentDidMount() {
    let selectedChannelFromLocalStorage = JSON.parse(localStorage.getItem("selectedChannel"));
    let selectedViewTypeFromLocalStorage = JSON.parse(localStorage.getItem("monetizedViewType"));

    if (selectedChannelFromLocalStorage) {
      this.setState({channel: selectedChannelFromLocalStorage});
    }

    if (selectedViewTypeFromLocalStorage) {
      this.setState({viewType: selectedViewTypeFromLocalStorage});
    }
  }

  columns = () => {

    const nameColumn =
      [
        {
          Header: localization.get("form.name"),
          id: 'name',
          accessor: product =>  (product.content) ? product.content.name || product.content.title : '',
          filterable: true,
          sortable: false,
        },
        {
          Header: localization.get("type"),
          id: "content_type",
          accessor: product =>  (product.content_type === "App\\ContentCreator") ? localization.get('contentCreators') : (product.content_type === "App\\ContentCreation") ? localization.get('table.share_material') :  localization.get('table.content_packs'),
          sortable: false,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <select
              style={{width: "100%"}}
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ""}
            >
              <option value="" selected/>
              <option value={"App\\ContentCreator"}>{localization.get('contentCreators')}</option>
              <option value={"App\\ContentCreation"}>{localization.get('table.share_material')}</option>
              <option value={"App\\ContentPack"}>{localization.get('table.content_packs')}</option>
            </select>
          ),
        },
        {
          Header: localization.get("form.days"),
          id: "days",
          accessor: "days",
          sortable: false,
          filterable: false
        },
        {
          Header: localization.get("table.status"),
          id: "active",
          accessor: product => <span>{product.active ? localization.get('table.active') : localization.get('table.inactive')}</span>,
          sortable: false,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <select
              style={{width: "100%"}}
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ""}
            >
              <option value="" selected/>
              <option value={"1"}>{localization.get('table.active')}</option>
              <option value={"0"}>{localization.get('table.inactive')}</option>
            </select>
          ),
        },
        {
          Header: `${localization.get("form.price")} AR$`,
          id: "priceARS",
          accessor: product => <span>{(!!product.prices.find(price => price.currency === "ARS") ? product.prices.find(price => price.currency === "ARS").price : 0)} AR$</span>,
          sortable: false,
          filterable: false
        },
        {
          Header: `${localization.get("form.price")} US$`,
          id: "priceUSD",
          accessor: product => <span>{(!!product.prices.find(price => price.currency === "USD") ? product.prices.find(price => price.currency === "USD").price : 0)} US$</span>,
          sortable: false,
          filterable: false
        },
        {
          Header: `${localization.get("table.purchases")}`,
          id: "purchasesCount",
          accessor: 'purchasesCount',
          sortable: false,
          filterable: false,
          width: 50
        },
        {
          Header: localization.get("table.stock"),
          id: "stock",
          accessor: product => product.has_stock_control ? (product.stock > 5 ? localization.get("table.stock.normal") : product.stock !== 0 ? localization.get("table.stock.low") : localization.get("table.stock.empty")) : localization.get("table.stock.normal") ,
          sortable: true,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <select
              style={{width: "100%"}}
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ""}
            >
              <option value="" selected>{localization.get("table.stock.all")}</option>
              <option value="low">{localization.get("table.stock.low")}</option>
              <option value="normal">{localization.get("table.stock.normal")}</option>
              <option value="out_of_stock">{localization.get("table.stock.empty")}</option>
            </select>
          ),
          filterMethod: (filter, row) => {
            if (filter.value === "") {
              return true;
            }
            const stockLimit = 5;
            const stock = row[filter.id];
            
            if (filter.value === "low") {
              return stock <= stockLimit;
            }
            return stock > stockLimit;
          }
        }
      ]

    const actionColumns = {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: product => {
        const builder = new TableButtonsBuilder()
        builder.withOther(
          <TableButton title={localization.get("product.copy_editorial_link")}
            onClick={() => copyStringToClipboard(`#product_${product.id}`)}>
            <FileCopy/>
          </TableButton>
        )
        builder.withEdit(makeUrlWithTableParams(`/panel/products/${product.id}/edit`))
        if(product.deleteable) builder.withDelete(
          (product.content) ? product.content.name || product.content.title : '',
          () => request.products.delete(product.id),
          state => {
            if (state === "passed") {
              this.refs.table.removeById(product.id)
            }
          })

       return builder.build();
      }
    }

    return nameColumn.concat([actionColumns])
  }

  render = () => {
    const { channel, loaded } = this.state;

    return !loaded ? <div></div> :
      (
        <>
          <div style={{float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
            <FormControl style={{float: "left", position: "relative", width: "100%"}}>
              <RadioGroup
                name="viewType"
                value={this.state.viewType}
                onChange={event => {
                  localStorage.setItem("monetizedViewType", `${JSON.stringify(event.target.value)}`);
                  this.setState({viewType: event.target.value},
                    () => this.props.onCurrentTableChange(this.state.viewType))
                }}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="products" control={<Radio color="default"/>} label={localization.get('products')}/>
                <FormControlLabel value="contentPacks" control={<Radio color="default"/>}
                                  label={localization.get('content_packs')}/>
                <FormControlLabel value="productCodes" control={<Radio color="default"/>}
                                  label={localization.get('table.codes')}/>
                <FormControlLabel value="purchases" control={<Radio color="default"/>}
                                  label={localization.get('table.purchases')}/>
              </RadioGroup>
            </FormControl>
            <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
              <span>{localization.get('channel')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('channel')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={channel => {
                  localStorage.setItem("selectedChannel", `${JSON.stringify(channel)}`);
                  return this.setState({channel: null}, () => this.setState({channel},
                    () => this.state.channel && this.state.viewType === "products" && table.forceUpdate()))
                }}
                value={channel}
                defaultOptions
                isClearable
                loadOptions={inputValue => {
                  let filters = [{column: "name", filter: inputValue}]
                  return request.channel.getAll({filterBy: filters}, '')
                    .then(res => res.map(client => ({value: client.id, label: client.name})))
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", float: "left", position: "relative" }}>

            {this.state.viewType === "products" ?
              <>
                {channel &&
                  <ServerSidePaginationTable
                    ref={r => table = r}
                    columns={this.columns()}
                    requestTableData={paginationConfig => request.products.getAll(this.state.channel.value, paginationConfig)}
                  />
                }
              </>
              : this.state.viewType === "purchases" ?
                <PurchaseTable channel={channel} />
              : this.state.viewType === "contentPacks" ?
                <ContentPacksTable channel={channel} />
              : channel ?
                <ProductCodesTable channel={channel} />
              : null
            }
          </div>
        </>
      )
  }
}

const copyStringToClipboard = (text) => {
  const fallbackCopyTextToClipboard = (text) =>  {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(function() {
    GlobalSnackbar.show({
      message: localization.get('copy.success'),
      type: GlobalSnackbarTypes.SUCCESS
    })
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export default Table
