import React from 'react';
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Redeem, ShoppingCart } from "@material-ui/icons";
import { channelPathGenerator, getStoredUser } from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import { slugify } from "../../../api-client/core/authentication/utils";
import { get } from 'lodash';

class BuyButton extends React.Component {
  getReturnUrl = (product) => {
    if (!product) return window.location.href;

    const contentMap = {
      'App\\ContentCreator': (product) => {
        const creator = get(product, 'content');
        return creator ? channelPathGenerator(`contenido/${creator.id}-${slugify(creator.name)}`) : null;
      },
      'App\\ContentCreation': (product) => {
        const creation = get(product, 'content');
        return creation ? channelPathGenerator(`contenido/${get(product, 'content.content_creator_id')}-${creation.title}`) : null;
      },
      'App\\ContentPack': (product) => {
        const targetProduct = get(product, 'content.products[0]');
        if (!targetProduct) return null;
        return this.getReturnUrl({ ...targetProduct, content_type: targetProduct.content_type });
      }
    };

    const contentType = get(product, 'content_type');
    return contentMap[contentType](product) || window.location.href;
  }

  renderGiftButton = (returnUrl) => {
    const { product, textColor, onShowLogin, onOpenProductDialog } = this.props;

    return (
      <IconButton
        style={{ marginLeft: 5, color: textColor }}
        title={localization.get('gift')}
        onClick={() => {
          if (!getStoredUser()) {
            onShowLogin();
            return;
          }
          onOpenProductDialog({
            productId: get(product, 'id'),
            returnUrl,
            isGift: true
          });
        }}
      >
        <Redeem/>
      </IconButton>
    );
  }

  renderBuyButton = (returnUrl, buttonText) => {
    const { product, textColor, onShowLogin, onOpenProductDialog } = this.props;

    console.log(get(product, 'is_giftable'), this.props.isGiftable);

    return (
      <>
        <span style={{ color: textColor }}>
          {buttonText || localization.get("buy")}
        </span>
        <IconButton
          style={{ marginLeft: 5, color: textColor }}
          title={localization.get('buy')}
          onClick={() => {
            if (!getStoredUser()) {
              onShowLogin();
              return;
            }
            onOpenProductDialog({
              productId: get(product, 'id'),
              returnUrl,
              isGift: false
            });
          }}
        >
          <ShoppingCart/>
        </IconButton>
        {(get(product, 'is_giftable') || this.props.isGiftable) && 
          this.renderGiftButton(returnUrl)}
      </>
    );
  }

  render() {
    const { 
      product,
      contentCreator,
      textColor,
      onlySocialButton,
      showBuyButton,
      preview
    } = this.props;

    if (onlySocialButton) return null;

    if (!product && !(!getStoredUser() && get(contentCreator, 'buyButton'))) return null;

    const returnUrl = this.getReturnUrl(product);

    if (get(product, 'already_bought')) {
      if (get(product, 'is_giftable')) {
        return (
          <>
            <span style={{ color: textColor }}>{localization.get("gift")}</span>
            {this.renderGiftButton(returnUrl)}
          </>
        );
      }
      return (
        <Link to={returnUrl} target="_blank">
          <span style={{ color: textColor }}>
            {localization.get("product.go_to_content")}
          </span>
        </Link>
      );
    }

    return showBuyButton ? 
      this.renderBuyButton(returnUrl, get(contentCreator, 'buyButton.text')) :
      this.renderBuyButton(returnUrl);
  }
}

export default BuyButton; 