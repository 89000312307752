import React from "react";
import {Button, Dialog, Radio, withStyles, Tooltip, Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";
import "./index.css"
import {request} from "../../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";
import localization from "../../../config/localization";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import {Send, Close, Check, Warning} from "@material-ui/icons";
import {channelPathGenerator, getStoredChannel, getStoredUser} from "../../../api-client/core/authentication/utils";
import AddressInput from "../AddressInput";
import ContactEmailDialog from "./ContactEmailDialog";
import CustomButton from '../../CustomButtons/Button';
import GiftDialog from "./GiftDialog";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { onImageError } from '../../../layouts/Home/HomeElements/utils';
import { get } from 'lodash';

const decorateProduct = (product) => {
  product.has_delivery = product.shipments && product.shipments != 'none';
  let shipments = [];
  if (product.productShipments) {
    let withdrawalAddresses = product.productShipments.filter(shipment => shipment.address);
    let deliveryZones = product.productShipments.filter(shipment => shipment.channelShipment);
    if (['both', 'withdrawal'].includes(product.shipments)) {
      withdrawalAddresses.map(shipment => ({...shipment, label: localization.get("product.withdrawal_label", shipment.address.name)})).forEach(s => shipments.push(s))
    }
    if (['both', 'delivery'].includes(product.shipments)) {
      deliveryZones.map(shipment => ({...shipment, label: localization.get("product.delivery_label", shipment.channelShipment.location)})).forEach(s => shipments.push(s))
    }
  } 
  product.shipments = shipments;
  product.giftable = !!product.is_giftable;
  return product;
}

class ProductDialog extends React.Component {
  constructor(props) {
    super(props);
    let channel = getStoredChannel();
    let gateways = (channel && channel.paymentGateways) || [];
    gateways = gateways.filter(g => g.active).map(g => g.gateway);
    this.state = {
      product: null,
      loaded: false,
      code: "",
      discount: 0,
      error: null,
      codeSuccess: null,
      channel: channel,
      gateways: gateways,
      selectedGateway: gateways.length == 0 ? null : gateways.includes('mercadopago') ? 'mercadopago' : gateways[0],
      buying: false,
      step: 1,
      selectedShipment: '',
      selectedAddress: getStoredUser() && getStoredUser().address,
      selectedEmail: getStoredUser() && getStoredUser().email,
      giftEmail: '',
      giftSenderName: getStoredUser() && getStoredUser().name,
      giftMessage: '',
    };
  }

  componentDidMount () {
    if (this.props.productTargetId) {
      request.products.getAllForEntity(this.props.productTargetType, this.props.productTargetId)
      .then(product => {product = decorateProduct(product); this.setState({product: product, loaded: true})})
        .catch(err => this.props.onClose())
    }

    if (this.props.productId) {
      request.products.getForChannel(this.props.productId)
        .then(product => {product = decorateProduct(product); this.setState({product: product, loaded: true})})
        .catch(err => this.props.onClose())
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.productTargetId &&
      ( nextProps.productTargetType !== this.props.productTargetType ||
      nextProps.productTargetId !== this.props.productTargetId ))
    {
      request.products.getAllForEntity(nextProps.productTargetType, nextProps.productTargetId)
        .then(product => {product = decorateProduct(product); this.setState({product: product, loaded: true})})
        .catch(err => this.props.onClose())
    }

    if (nextProps.productId &&
      ( nextProps.productId !==  this.props.productId)) {
      request.products.getForChannel(nextProps.productId)
        .then(product => {product = decorateProduct(product); this.setState({product: product, loaded: true})})
        .catch(err => this.props.onClose())
    }
  }

  makePurchase = (gateway, isGift=false) => {
    if (this.state.buying) return;
    let code = this.state.code;
    code = code ? code.trim() : '';
    if (code && code.length > 0 && !this.state.codeSuccess && !this.state.error) {
      this.testCode();
      return;
    }
    if (this.state.product.has_delivery) {
      if (!this.state.selectedShipment) {
        GlobalSnackbar.show({message: localization.get("product.no_shipment_selected_error"), type: GlobalSnackbarTypes.ERROR})
        return;
      } else if (this.state.selectedShipment.channelShipment) {
        if (!this.state.selectedAddress) {
          GlobalSnackbar.show({message: localization.get("product.no_address_selected"), type: GlobalSnackbarTypes.ERROR})
          this.addressRef && this.addressRef.open();
          return;
        }
      } else if (!this.state.selectedEmail) {
        GlobalSnackbar.show({message: localization.get("product.no_email_selected"), type: GlobalSnackbarTypes.ERROR})
        return;
      }
    } else if (isGift) {
      if(!this.state.giftEmail || this.state.giftEmail.length == 0) {
        GlobalSnackbar.show({message: localization.get("product.no_gift_email"), type: GlobalSnackbarTypes.INFO});
        this.setState({showGiftDialog: true})
        return;
      }
    }

    this.setState({buying: true});
    if (isGift) {
      let callbackUrl = `${window.location.protocol}//${window.location.hostname}${channelPathGenerator('mis-compras')}`;
      request.products.makeGift(this.state.product.id, {gateway: gateway, code: code, callback: callbackUrl, from: this.state.giftSenderName, to: this.state.giftEmail, dedication: this.state.giftMessage })
        .then(response => {
          analyticsInstance.buy({
            name: this.state.product.content.name || this.state.product.content.title || this.props.name,
            gateway: gateway,
            code: code,
            type: "Gift",
          })
          return response.status == 'authorized' ? window.location.reload() : window.location.href = response.init_point
        })
        .catch(error => {
          console.log(error);
          if (error && error.status == 409) {
            GlobalSnackbar.show({message: localization.get("product.already_bought_error"), type: GlobalSnackbarTypes.ERROR})
            setTimeout(() => window.location.href = this.props.returnUrl ? this.props.returnUrl : window.location.href, 3000);
          } else {
            GlobalSnackbar.showGenericError();
            this.handleClose()
          }
        })
    } else {
      request.products.makePurchase(this.state.product.id,  {gateway: gateway, code: code, callback: window.location.href, shipment: this.state.selectedShipment})
        .then(response => {
          analyticsInstance.buy({
            name: this.state.product.content.name || this.state.product.content.title || this.props.name,
            gateway: gateway,
            code: code,
            type: "Self",
          })

          return response.status == 'authorized' ? window.location.reload() : window.location.href = response.init_point
        })
        .catch(error => {
          if (error && error.status == 409) {
            GlobalSnackbar.show({message: localization.get("product.already_bought_error"), type: GlobalSnackbarTypes.ERROR})
            setTimeout(() => window.location.href = this.props.returnUrl ? this.props.returnUrl : window.location.href, 3000);
          } else {
            GlobalSnackbar.showGenericError();
            this.handleClose()
          }
        })
    }
  }

  handleClose = () => this.setState({product: null, loaded: null, code: "", codeSuccess: null, error: null, discount: 0, buying: false, step: 1, selectedShipment: ''}, this.props.onClose)

  testCode = () => {
    if (this.state.codeSuccess) return;
    let code = this.state.code;
    code = code ? code.trim() : '';
    if (code.length == 0) return;
    this.setState({error: null, codeSuccess: null, discount: 0}, () => request.productCodes.checkIfValid(this.state.product.id, code)
      .then(response => {
        if (response.isValid) {
          this.setState({discount: response.discount, error: null, codeSuccess: localization.get("product.code.success")})
        } else {
          this.setState({discount: response.discount, error: localization.get("product.code.error"), codeSuccess: null})
        }
      })
      .catch(error => {
        GlobalSnackbar.showGenericError();
      })
    )
  }

  getGatewayLabel = (gateway) => {
    switch(gateway) {
      case 'mercadopago': return localization.get('pay_with', "Pesos");
      case 'paypal': return localization.get('pay_with', "Dolares");
      default: return gateway;
    }
  }

  getGatewayCurrency = (gateway) => !gateway ? 'ARS' : gateway == 'mercadopago' ? 'ARS' : 'USD'; 

  priceWithDiscount = () => {
    let currency = this.getGatewayCurrency(this.state.selectedGateway);
    let price = this.state.product && this.state.product.prices && this.state.product.prices.find(p => p.currency == currency);
    price = price ? price.price : 0;
    price =  price - (this.state.discount * price / 100); 
    price = this.state.selectedShipment ? price + parseFloat(this.getShipmentPrice(this.state.selectedShipment)) : price;
    return (Math.round(price * 100) / 100).toFixed(0);
  } 

  getShipmentPrice = (shipment) => {
    if (shipment.channelShipment) {
      let currency = this.getGatewayCurrency(this.state.selectedGateway);
      let price = shipment.channelShipment && shipment.channelShipment.prices && shipment.channelShipment.prices.find(p => p.currency == currency);
      price = price ? price.price : 0;
      return price;
    } else {
      return 0;
    }
  }

  hasStock = () => {
    const stock = get(this.state.product, 'stock', 0);
    const useStock = get(this.state.product, 'has_stock_control', false);
    
    return !useStock || stock > 0;
  }

  renderStockWarning = () => {
    if (!this.hasStock()) {
      return (
        <div style={{
          backgroundColor: "rgba(255,0,0,0.1)", 
          padding: "10px", 
          borderRadius: "4px",
          marginTop: "10px",
          display: "flex",
          alignItems: "center"
        }}>
          <Warning style={{ color: "#f44336", marginRight: "8px" }} />
          <span style={{ color: "#f44336" }}>
            {localization.get("product.no_stock")}
          </span>
        </div>
      );
    }
    return null;
  }

  renderProductInfo = () => {
    let {product, gateways} = this.state;
    return (
      <div className={"product-info"} style={{paddingTop: 10}}>
        <h3 style={{marginTop: 10, maxHeight: 80, overflow: "hidden", fontSize: 18, fontWeight:"bold"}}>
          {get(product, 'content.name') || get(product, 'content.title') || this.props.name}
        </h3>
        <p style={{maxHeight: 50, overflow: "auto"}}>{product.description}</p>
        
        {this.renderStockWarning()}

        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "fit-content"}}>
          <p style={{marginTop: "15!important"}}>{localization.get("product.code")}:</p>
          <input
            id="code"
            className="code-input"
            value={this.state.code}
            onChange={event => this.setState({code: event.target.value, error: null, codeSuccess: null, discount: 0})}
            placeholder={localization.get("table.search")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.testCode();
              }
            }}
            disabled={this.state.codeSuccess != null}
          />
          <Tooltip placement="bottom" title={localization.get("product.code.apply")}>
            <Send onClick={this.testCode} style={{cursor: "pointer", marginLeft: 20}} />
          </Tooltip>
        </div>
        <div style={{display: "flex", flexDirection: "row", marginLeft: 60, height: 20}}>
          {this.state.error && <p style={{color: 'white', backgroundColor: "rgba(255,0,0,0.7)", padding: "0 4px", borderRadius: 5, fontSize: "0.8em", display: "flex", alignItems: "center"}}><Close style={{fontSize: 15, marginRight: 5}}/>{this.state.error}</p>}
          {this.state.codeSuccess && <p style={{color: 'white', backgroundColor: "rgba(0,128,0,0.7)", padding: "0 4px", borderRadius: 5, fontSize: "0.8em", display: "flex", alignItems: "center"}}><Check style={{fontSize: 15, marginRight: 5}}/> {this.state.codeSuccess}</p>}
        </div>
        <div className="price-container">
          {gateways.length > 1 &&
            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: "center"}}>
              {gateways.map((g, i) => (
                <div style={{display: "flex", alignItems: 'center', marginRight: 20}}>
                  <CustomRadio
                    checked={this.state.selectedGateway === g}
                    onChange={(e) => this.setState({selectedGateway: g})}
                    value={g}
                    size="small"
                    key={i}
                    inputProps={{ 'aria-label': this.getGatewayLabel(g) }}
                  />
                  {this.getGatewayLabel(g)}
                </div>
              ))}
            </div>
          }
          <div className={"product-price"}>
            <h3 style={{textAlign: "left", marginTop: 0}}>{localization.get('product.cost')}:</h3>
            <h3 style={{textAlign: "right", flex: "1", marginTop: 0}}>{this.getGatewayCurrency(this.state.selectedGateway)} {this.priceWithDiscount()}</h3>
          </div>
        </div>
      </div>
    )
  }

  goToStep2 = () => {
    let code = this.state.code;
    code = code ? code.trim() : '';
    if (code && code.length > 0 && !this.state.codeSuccess && !this.state.error) {
      this.testCode();
      return;
    }
    this.setState({step: 2});
  }

  renderGiftInfo = () => {
    let {giftEmail, giftMessage, giftSenderName} = this.state;
    return (
      <div className={"product-info"} style={{paddingTop: 10}}>
        <h3 style={{marginTop: 10}}>{localization.get("gift_content")}</h3>
        <GiftDialog
          email={giftEmail} 
          name={giftSenderName} 
          message={giftMessage} 
          showDialog={this.state.showGiftDialog} 
          handleCloseDialog={() => this.setState({showGiftDialog: false})} 
          onSubmit={({email, name, message}) => this.setState({giftEmail: email, giftSenderName: name && name.length > 0 ? name : this.state.giftSenderName, giftMessage: message, showGiftDialog: false})}
        />
        {(giftEmail && giftEmail.length > 0) ?
          <div style={{padding: "10px 0", maxHeight: '175px', overflow: "auto"}}>
            <p dangerouslySetInnerHTML={{ __html: localization.get("gift.show_data", giftEmail, giftSenderName)}}/>
            <p>{(giftMessage && giftMessage.length > 0) ? `${localization.get("form.gift_message")}: ${giftMessage}` : `(${localization.get("gift.no_gift_message")})`}</p>
          </div>
        : 
          <div style={{padding: "10px 0"}}>
            <p>{localization.get("gift.no_gift_data")}</p>
          </div>
        }
        <CustomButton
          style={{
            padding: 8,
            textTransform: "capitalize",
            marginLeft: 5
          }}
          color="linkedin"
          onClick={() => this.setState({showGiftDialog: true})}
          size="sm"
        >
          {(!giftEmail || giftEmail.length == 0) ? localization.get("form.gift_data.create") : localization.get("form.gift_data.update")}
        </CustomButton>
      </div>
    )
  }

  renderAddressInfo = (address) => {
    if (address) {
      return (
        <div style={{padding: "10px 0"}}>
          <p>{address.address}, {address.city}</p>
          {address.google_maps_link && <p><a target="_blank" href={address.google_maps_link}>{localization.get("product.see_in_google_maps")}</a></p>}
          {address.description && <p><b>{localization.get("product.indications")}:</b> {address.description}</p>}
        </div>
      )
    }
  }

  renderDeliveryInfo = () => {
    let {product} = this.state;
    return (
      <div className={"product-info"} style={{paddingTop: 10}}>
        <h3 style={{marginTop: 10}}>{localization.get("product.shipment_options")}</h3>
        {product.shipments &&
          <div style={{display: 'flex', flexWrap: 'wrap', alignItems: "center"}}>
            <FormControl fullWidth>
              <InputLabel>{localization.get("product.shipment_options")}</InputLabel>
              <Select
                style={{width: "100%"}}
                value={this.state.selectedShipment}
                onChange={e => this.setState({selectedShipment: e.target.value})}
              >
                {product.shipments.map((shipment, i) => <MenuItem value={shipment}>{shipment.label}{shipment.channelShipment && ` (${this.getGatewayCurrency(this.state.selectedGateway)} ${this.getShipmentPrice(shipment)})`}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
        }
        {this.state.selectedShipment && 
          <>
            {this.state.selectedShipment.address && 
              <>
                {this.renderAddressInfo(this.state.selectedShipment.address)}
                <ContactEmailDialog 
                  email={this.state.selectedEmail} 
                  showDialog={this.state.showEmailDialog} 
                  handleCloseDialog={() => this.setState({showEmailDialog: false})} 
                  onSubmit={(email) => this.setState(
                    {selectedEmail: email, showEmailDialog: false}, 
                    () => request.user.update({email: email, userId: getStoredUser() && getStoredUser().id}).catch(err => {GlobalSnackbar.show({message: err.status == 422 ? localization.get("product.email_in_use_error") : localization.get("product.email_not_updated_error"), type: GlobalSnackbarTypes.ERROR}); return this.setState({selectedEmail: ''});})
                  )}
                />
                {getStoredUser() && <div style={{width: "100%", display: 'flex', alignItems: 'center', marginTop: 10, justifyContent: "space-between"}}>
                  <div>
                    {this.state.selectedEmail ? 
                      localization.get("product.contact_email", this.state.selectedEmail)
                      :
                      localization.get("form.email.no_email")
                    }
                  </div>
                  {!this.state.selectedEmail && 
                    <CustomButton
                      style={{
                        padding: 8,
                        textTransform: "capitalize",
                        marginLeft: 5
                      }}
                      color="linkedin"
                      onClick={() => this.setState({showEmailDialog: true})}
                      size="sm"
                    >
                      {localization.get("form.email.create")}
                    </CustomButton>
                  }
                </div>} 
              </>
            }
            {this.state.selectedShipment.channelShipment &&
              <AddressInput
                ref={ref => this.addressRef = ref}
                address={this.state.selectedAddress}
                withEmail={!this.state.selectedEmail}
                onEmailChange={(email) => this.setState({selectedEmail: email})}
                onAddressChange={(address) => this.setState({selectedAddress: address}, () => request.user.updateAddress(address))}
                buttonColor="linkedin"/>
            }   
          </>
        }
        
        <div className="price-container">
          <div className={"product-price"}>
            <h3 style={{textAlign: "left", marginTop: 0}}>{localization.get('product.cost')}:</h3>
            <h3 style={{textAlign: "right", flex: "1", marginTop: 0}}>{this.getGatewayCurrency(this.state.selectedGateway)} {this.priceWithDiscount()}</h3>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    let { product, buying, selectedGateway, step } = this.state;
    let isMobile = window.innerWidth < 600;
    let fullScreenStyle = { maxWidth: "100vw", width: 750, margin: 5}
    let isGift = product && product.giftable && this.props.isGift;
    const { classes } = this.props;
    const hasStock = this.hasStock();

    return (
      <Dialog
        disableEnforceFocus
        open={this.props.open}
        onEscapeKeyDown={this.handleClose}
        onError={this.handleClose}
        onBackdropClick={this.handleClose}
        PaperProps={{
          style: window.innerWidth > 450 ?
            { maxWidth: isMobile ? "100vw" : "90vw", width: 750} :
            fullScreenStyle
      }}
        classes={{paper: classes.dialogPaper}}
      >
        <div
          className={"product-container"}
          style={window.innerWidth > 450 ?
            { maxWidth: isMobile ? "100vw" : "90vw", width: 750} : fullScreenStyle}
        >
          {product ?
            <>
              {!isMobile && ((product && product.content && product.content.image) || this.props.imageUrl) && 
                <div className={"product-image-container"} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <img src={(product && product.content && product.content.image) ? request.file.image.makeFittedUrl(product.content.image.id, 350, 350, 'file', 'png') : this.props.imageUrl} style={{maxHeight: "350px", maxWidth: "350px", marginLeft: 20}} onError={onImageError}/>
                </div>
              }
              <div className={"product-info-container"}>
                {(!(product.has_delivery || isGift) || step == 1) && this.renderProductInfo()}
                {(product.has_delivery && step == 2) && this.renderDeliveryInfo()}
                {(!product.has_delivery && isGift && step == 2) && this.renderGiftInfo()}
                <div className={"product-actions"}>
                  <Button variant="text" style={{fontSize: 12}} onClick={((product.has_delivery || isGift) && step == 2) ? () => this.setState({step: 1}) : this.handleClose}>{localization.get('talent_profile_modal.step_3.back_button')}</Button>
                  {!selectedGateway ?
                    <div>{localization.get("product.gateway_error.title")}<br/>{localization.get("product.gateway_error.message")}</div>
                  :
                    ((!(product.has_delivery || isGift) || step == 2) ? 
                      <>
                        {selectedGateway == 'mercadopago' && 
                          <Button 
                            style={{backgroundColor: "#069af3", color: 'white', textTransform: 'none'}} 
                            variant="contained" 
                            disabled={!product || buying || !hasStock} 
                            onClick={() => this.makePurchase('mercadopago', isGift)}
                          >
                            {hasStock ? localization.get("pay") : localization.get("product.out_of_stock")}
                          </Button>
                        }
                        {selectedGateway == 'paypal' && 
                          <Button 
                            style={{backgroundColor: "#0070BA", color: 'white', textTransform: 'none'}} 
                            variant="contained" 
                            disabled={!product || buying || !hasStock} 
                            onClick={() => this.makePurchase('paypal', isGift)}
                          >
                            {hasStock ? localization.get("pay_with", "PayPal") : localization.get("product.out_of_stock")}
                          </Button>
                        }
                      </>
                      :
                      <Button 
                        style={{backgroundColor: selectedGateway == 'paypal' ? "#0070BA" : "#069af3", color: 'white', textTransform: 'none'}} 
                        variant="contained" 
                        disabled={!hasStock}
                        onClick={() => this.goToStep2()}
                      >
                        {isGift ? localization.get("gift") : localization.get("form.continue")}
                      </Button>
                    )
                  }
                </div>
              </div>
            </>
          :
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
              <img
                style={{ width: 60, height: 60 }}
                src={LoadingSpinner}
                alt="Loading logo"
              />
            </div>
          }
        </div>

      </Dialog>
    )
  }
}

export default withStyles({
  dialogPaper: {
    minHeight: '100vh',
    minWidth: '100vw',
    maxHeight: '100vh',
    maxWidth: '100vw',
    backgroundColor: "rgba(0,0,0,0.8)",
    height: '100vh',
    width: '100vw',
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(10px)"
  },
})(ProductDialog)

const CustomRadio = withStyles({
  root: {
    color: 'var(--navbar-text-color, black)',
    width: '30px',
    justifyContent: 'flex-start',
    '&$checked': {
      color: 'var(--navbar-text-color, black)',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);