import {isNullOrUndefined} from "util";

export const makeFormData = ({owner, productType, days, active, translations, prices, shipments, addresses, channelShipments, giftable, stock, hasStock}) => {
  const form = new FormData();
  console.log(giftable)

  prices.forEach((price, index) => {
    form.append(`prices[${index}][currency]`, price.currency)
    form.append(`prices[${index}][price]`, price.price)
  });
  if (owner) form.append("owner_id", owner.value);
  if (productType) form.append("owner_type", productType);
  if (days) form.append("days", days);
  if (!isNullOrUndefined(active)) form.append("active", active ? 1 : 0);
  if (!isNullOrUndefined(giftable)) form.append("is_giftable", giftable ? 1 : 0);
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description);
    });
  }
  if (shipments) form.append("shipments", shipments);
  addresses.forEach((address, index) => {
    form.append(`productShipments[${index}][address_id]`, address)
  })

  channelShipments.forEach((shipment, index) => {
    form.append(`productShipments[${addresses.length + index}][channel_shipment_id]`, shipment)
  })
  if (!isNullOrUndefined(stock)) form.append("stock", !hasStock ? 0 : stock);
  if (!isNullOrUndefined(hasStock)) form.append("has_stock_control", hasStock ? 1 : 0);

  return form;
};

export const includeQuery = "include=prices;content";
