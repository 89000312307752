import React, {Component} from "react";
import {request} from "api-client";
import UsersFormView from "./UsersFormView";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {getStoredUser, ROLES, userisMultiClient,} from "../../../api-client/core/authentication/utils";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import RestrictedTagSelection from "./RestrictedTagSelection";
import {get} from "lodash";

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      email: props.email,
      description: props.description,
      client: props.client,
      image: { src: props.image, file: null },
      clients: [],
      roles: [],
      represents: props.represents || [],
      roleId: props.isChannel ? ROLES.userChannelId : props.roleId,
      language: props.language || '',
      canLicense: props.canLicense,
      accessLevel: props.accessLevel || 0,
      canCreateClients: props.canCreateClients,
      canSeeLicenses: props.canSeeLicenses,
      canCreateContentCreators: props.canCreateContentCreators,
      roleInClient: props.roleInClient || '',
      selectedArtists: props.selectedArtists || [],
      selectedClouds: props.selectedClouds || [],
      isArtist: (props.selectedArtists && props.selectedArtists.length > 0) ? 1 : 0,
      selectedCatalogs: props.selectedCatalogs || [],
      canEditAdmins: props.canEditAdmins || false,
      selectedChannels: props.channels ? props.channels.filter(channel => !!channel.channel).map(channel => (
        {
          value: channel.channel.id, 
          label: channel.channel.name,
          purpose: channel.channel.purpose,
          restricted: channel.restricted,
          restrictedTags: channel.restricted_tags ? channel.restricted_tags.material_tags.split(',') : []
      })) : [],
      isChannel: props.isChannel,
      clientForEditor: props.clientForEditor || '',
      providers: props.providers?  props.providers.map(providers => providers.provider) : [],
      mibaId: "",
      gcbaId: "",
      address: props.address || null,
      showAddressDialog: false,
      canAccessMusic: props.canAccessMusic || false,
      canAccessClients: props.canAccessClients || false,
      canAccessRanking: props.canAccessRanking || false,
      canAccessConfig: props.canAccessConfig || false,
      canAccessLicense: props.canAccessLicense || false,
      canAccessReports: props.canAccessReports || false,
      createPassword: false,
      password: "",
      selectedBrand: props.brand || null,
      canEditContentCreators: props.canEditContentCreators || false,
      identityNumber: props.identityNumber || '',
      selectedTags: props.selectedTags || [],
      restrictChannel: null,
      manageableChannels: props.manageableChannels ? props.manageableChannels.map(channel => (
        {
          value: channel.id, 
          label: channel.name,
      })) : [],
      restrictManageableChannels: props.manageableChannels ? props.manageableChannels.length > 0 : false
    };
  }

  requestClients = () =>
    request.client.getAll({}, '')
      .then(clients => {
        if (userisMultiClient()) {
          clients = clients.filter(client => (getStoredUser().clientRoles.some(clientRole => client.id == clientRole.client.id)) &&
            (getStoredUser().clientRoles.find(clientRole => clientRole.client.id == client.id).role.id == ROLES.CLIENT_ADMIN))
        }
        return clients
      })
      .then(clients => this.setState({ clients }));

  requestRoles = () =>
    request.role
      .getAll()
      .then(roles => roles.filter(role => !([ROLES.trialId, ROLES.projectInviteeId].includes(role.id))))
      .then(roles => this.setState({ roles }));


  handleSubmitRequest = () =>
    this.props.submitRequest(this.state).then(() => {
      if (this.props.type === "create" && !this.state.password) {
        GlobalConfirmationDialog.show({
          title: localization.get("user.created"),
          content: localization.get("user.email_sent", this.state.email),
          request: () => Promise.resolve(),
          confirmationLabel: localization.get("accept"),
          onClose:  this.props.isChannel ? this.props.history.push(makeUrlWithTableParams("/panel/channel_users"))
            : this.props.history.push(makeUrlWithTableParams("/panel/users"))

        });
      } else {
        this.props.isChannel ? this.props.history.push(makeUrlWithTableParams("/panel/channel_users"))
          : this.props.history.push(makeUrlWithTableParams("/panel/users"))
      }
    });

  onImageChange = image => this.setState({ image });

  onAsyncArtistChange = selectedArtists => this.setState({ selectedArtists })
  onAsyncCloudsChange = selectedClouds => this.setState({ selectedClouds })

  onSubmitValidation = () => {
    if (
      ([ROLES.SINGLE_CLIENT].includes(this.state.roleId) && !this.state.client) ||
      (this.state.roleId === ROLES.MULTI_CLIENT && this.state.represents.length === 0)
    ) {
      GlobalSnackbar.show({message: localization.get('user.must_select_one_client'), type: GlobalSnackbarTypes.ERROR})
      return false
    } 

    if ([ROLES.MUSIC_SUPERVISOR].includes(this.state.roleInClient) && !this.state.selectedBrand) {
        GlobalSnackbar.show({message: localization.get('user.must_select_one_brand'), type: GlobalSnackbarTypes.ERROR})
        return false
    } 

    if ([ROLES.CONTENT_SUPERVISOR].includes(this.state.roleId) && this.state.selectedClouds.length === 0) {
      GlobalSnackbar.show({message: localization.get('user.must_select_one_cloud'), type: GlobalSnackbarTypes.ERROR})
      return false
    } 

    if (![ROLES.SUPERVISOR, ROLES.SYS_ADMIN].includes(this.state.roleId) && this.state.selectedChannels.length === 0) {
      GlobalSnackbar.show({message: localization.get('user.must_select_one_channel'), type: GlobalSnackbarTypes.ERROR})
      return false
    }

    return true
  }


  render = () => {
    const {
      disabled,
      name,
      email,
      description,
      client,
      image,
      clients,
      roles,
      roleId,
      canLicense,
      canCreateClients,
      canCreateContentCreators,
      represents,
      roleInClient,
      actualArtist,
      isArtist,
      language,
      selectedArtists,
      selectedClouds,
      canSeeLicenses,
      selectedCatalogs,
      canEditAdmins,
      clientForEditor,
      providers,
      mibaId,
      gcbaId,
      address,
      showAddressDialog,
      canAccessClients,
      canAccessRanking,
      canAccessConfig,
      canAccessLicense,
      canAccessReports,
      canAccessMusic,
      createPassword,
      password,
      accessLevel,
      canEditContentCreators,
      identityNumber,
      restrictChannel,
      manageableChannels,
      restrictManageableChannels
    } = this.state;
    
    return (
      <div>
        <FormViewContainer
          title={this.props.formTitle}
          initializationRequest={() => this.requestClients().then(() => this.requestRoles())}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.handleSubmitRequest}
          validation={this.onSubmitValidation}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          <RestrictedTagSelection 
            channel={restrictChannel}
            onClose={() => this.setState({restrictChannel: null})}
            restrictedTags={get(restrictChannel, "restrictedTags", [])}
            updateChannel={channel => this.setState({restrictChannel: channel, selectedChannels: this.state.selectedChannels.map(selectedChannel => selectedChannel.value === channel.value ? channel : selectedChannel)})}
          />
          <UsersFormView
            name={name}
            email={email}
            description={description}
            client={client}
            onClientChange={client => this.setState({client, represents: []})}
            onSoundProducerChange={client => this.setState({client, represents: [], roleInClient: ROLES.clientAdminId})}
            represents={represents}
            clients={clients}
            roleId={roleId}
            roles={roles}
            actualArtist={actualArtist}
            isArtist={isArtist}
            language={language}
            image={image}
            selectedArtists={selectedArtists}
            selectedClouds={selectedClouds}
            onAsyncArtistChange={this.onAsyncArtistChange}
            onAsyncCloudsChange={this.onAsyncCloudsChange}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            onImageChange={image => this.setState({ image })}
            disabled={disabled}
            canLicense={canLicense}
            onIsArtistChange={isArtist => this.setState({ isArtist, selectedArtists: [] })}
            onCanLicenseChange={canLicense => this.setState({ canLicense })}
            canCreateClients={canCreateClients}
            onCanCreateClientsChange={canCreateClients => this.setState({ canCreateClients })}
            canSeeLicenses={canSeeLicenses}
            onCanSeeLicensesChange={canSeeLicenses => this.setState({ canSeeLicenses })}
            canCreateContentCreators={canCreateContentCreators}
            onCanCreateContentCreatorsChange={canCreateContentCreators => this.setState({ canCreateContentCreators })}
            roleInClient={roleInClient}
            selectedCatalogs={selectedCatalogs}
            canEditAdmins={canEditAdmins}
            onChannelChange={selectedChannels => this.setState({ selectedChannels })}
            selectedChannels={this.state.selectedChannels}
            onCanEditAdminsChange={value => this.setState({canEditAdmins: value})}
            onCatalogsChange={selectedCatalog => {this.setState({selectedCatalogs: selectedCatalog})}}
            isChannel={this.props.isChannel}
            onClientForEditorChange={clientForEditor => this.setState({clientForEditor: null, selectedClouds: []}, () => this.setState({clientForEditor}))}
            clientForEditor={clientForEditor}
            providers={providers}
            mibaId={mibaId}
            gcbaId={gcbaId}
            address={address}
            onAddressChange={address => this.setState({ address, showAddressDialog: false })}
            handleAddressDialog={() => this.setState({showAddressDialog: !this.state.showAddressDialog})}
            showAddressDialog={showAddressDialog}
            canAccessClients={canAccessClients}
            canAccessRanking={canAccessRanking}
            canAccessConfig={canAccessConfig}
            canAccessLicense={canAccessLicense}
            canAccessReports={canAccessReports}
            canAccessMusic={canAccessMusic}
            createPassword={createPassword}
            password={password}
            creating={this.props.creating}
            selectedBrand={this.state.selectedBrand}
            onBrandChange={selectedBrand => this.setState({selectedBrand})}
            accessLevel={accessLevel}
            canEditContentCreators={canEditContentCreators}
            identityNumber={identityNumber}
            openRestrictAccessDialog={channel => this.setState({restrictChannel: channel})}
            manageableChannels={manageableChannels}
            restrictManageableChannels={restrictManageableChannels}
            onManageableChannelsChange={manageableChannels => this.setState({manageableChannels})}
          />
        </FormViewContainer>
      </div>
    );
  };
}

export default FormContainer;
