import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from "@material-ui/core";
import localization from "../../config/localization";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../api-client";
import {TextValidator} from "react-material-ui-form-validator";
import React from "react";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import AvailableLanguages from "../../config/localization/availableLanguages.json";

const FormContent = ({
  id,
  channel,
  channels,
  owner,
  productType,
  priceArs,
  priceUsd,
  days,
  onChange,
  onChannelChange,
  onOwnerChange,
  onTypeChange,
  disabled,
  active,
  onActiveChange,
  onGiftableChange,
  giftable,
  translations,
  onChangeTranslation,
  shipments,
  onShipmentsChange,
  hasShipments,
  onHasShipmentsChange,
  addresses,
  channelShipments,
  onAddressChange,
  onChannelShipmentChange,
  hasStock,
  stock,
}) => {
  return (
    <div>
      {!id ?
        <FormControl fullWidth>
          <span>{localization.get('channel')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('channel')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onChannelChange}
            validators={['required']}
            value={channel}
            loadOptions={(inputValue, callback) => {
              callback(channels.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              ))
            }}
            defaultOptions
          />
        </FormControl>
        :
        <>
          <TextValidator
            style={{ width: "100%" }}
            label={localization.get('channel')}
            name="channelName"
            value={channel.name}
            disabled={true}
          />
        </>
      }
      <br />
      <br />

      {!!channel &&
      <>
        <FormControl style={{width: "100%"}}>
          <span>{localization.get('form.type')}</span>
          <RadioGroup
            name="productType"
            value={productType}
            id="productType"
            onChange={e => onTypeChange( e.target.value)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="contentCreator" control={<Radio color="default"/>}
                              label={localization.get('contentCreators')}/>
            <FormControlLabel value="contentCreation" control={<Radio color="default"/>}
                              label={localization.get('table.share_material')}/>
            <FormControlLabel value="contentPack" control={<Radio color="default"/>}
                              label={localization.get('content_packs')}/>
          </RadioGroup>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              onChange={onActiveChange}
            />
          }
          label={localization.get("catalog.active")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={giftable}
              onChange={() => {
                onGiftableChange();
                if(hasShipments) onHasShipmentsChange()
              }}
            />
          }
          label={localization.get("form.giftable")}
        />

        <FormControl fullWidth>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get(productType === "contentCreator" ? 'products.contentCreators' : productType === "contentCreation" ? 'products.material' : 'content_packs')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onOwnerChange}
            validators={['required']}
            value={owner}
            defaultOptions
            loadOptions={async inputValue => {
              let filterBy = [{column: "channel_id", filter: channel.id || channel.value}];

              if(inputValue) {
                filterBy.push({column: "title", filter: inputValue})
              }else {
                filterBy.push({column: "per_page", filter: 15})
              }

              let req = (productType === "contentCreation")? request.contentCreation : (productType === "contentCreator")? request.contentCreator : request.contentPacks;
              return req.getAll({filterBy, orderBy: {column:productType==="contentCreation" ? "title" : "name", type: "ASC"}})
                .then(res => res.map(content => ({
                  ...content,
                  value: content.id,
                  label: `${content.name || content.title}`
                })))
            }}
          />
        </FormControl>
        <br/>
        <br/>
        <FormControl fullWidth style={{display: "flex", flexDirection: "row"}}>
          <div style={{width: "20%"}}>
            <InputLabel>{localization.get('form.currency')}</InputLabel>
            <Select
              style={{width: "100%"}}
              disabled={true}
              value="ARS"
            >
              <MenuItem value="ARS" selected>ARS</MenuItem>
            </Select>
          </div>
          <TextValidator
            style={{width: "80%", marginLeft: 20}}
            label={localization.get('form.price')}
            name="priceArs"
            id="priceArs"
            value={priceArs}
            type={"number"}
            onChange={onChange}
            validators={['minNumber:0']}
          />
        </FormControl>
        <FormControl fullWidth style={{display: "flex", flexDirection: "row"}}>
          <div style={{width: "20%"}}>
            <InputLabel>{localization.get('form.currency')}</InputLabel>
            <Select
              style={{width: "100%"}}
              disabled={true}
              value="USD"
            >
              <MenuItem value="USD" >USD</MenuItem>
            </Select>
          </div>
          <TextValidator
            style={{width: "80%", marginLeft: 20}}
            label={localization.get('form.price')}
            name="priceUsd"
            id="priceUsd"
            value={priceUsd}
            type={"number"}
            onChange={onChange}
            validators={['minNumber:0']}
          />
        </FormControl>
        <br/>
        <TextValidator
          style={{width: "100%"}}
          label={localization.get('form.days')}
          name="days"
          id="days"
          type={"number"}
          value={days}
          onChange={onChange}
        />
        <br/>
        <br/>
        {!giftable &&
          <FormControlLabel
            control={
              <Checkbox
                checked={hasShipments}
                onChange={onHasShipmentsChange}
              />
            }
            label={localization.get("product.has_shipments")}
          />
        }
        <br/>
        {hasShipments && 
          <>
            <FormControl style={{width: "100%"}}>
              <span>{localization.get('product.shipments.label')}</span>
              <RadioGroup
                name="shipments"
                value={shipments}
                id="shipments"
                onChange={e => onShipmentsChange( e.target.value)}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="delivery" control={<Radio color="default"/>}
                                  label={localization.get('product.shipments.delivery')}/>
                <FormControlLabel value="withdrawal" control={<Radio color="default"/>}
                                  label={localization.get('product.shipments.withdrawal')}/>
                <FormControlLabel value="both" control={<Radio color="default"/>}
                                  label={localization.get('product.shipments.both')}/>
              </RadioGroup>
            </FormControl>
            <br/>
            {hasShipments && shipments != 'none' &&
              <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                {['withdrawal', 'both'].includes(shipments) && channel.addresses && channel.addresses.length > 0 &&
                  <div style={{width: "50%", display: 'flex', flexDirection: "column"}}>
                    <span>{localization.get("product.withdrawal_zones")}</span>
                    {channel.addresses.map(a => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addresses.includes(a.id)}
                            onChange={() => onAddressChange(a.id)}
                          />
                        }
                        label={`${a.name} (${a.address})`}
                      />
                    ))}  
                  </div>
                }
                {['delivery', 'both'].includes(shipments) && channel.channelShipments && channel.channelShipments.length > 0 &&
                  <div style={{width: "50%", display: 'flex', flexDirection: "column"}}>
                    <span>{localization.get("product.delivery_zones")}</span>
                    {channel.channelShipments.map(cs => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={channelShipments.includes(cs.id)}
                            onChange={() => onChannelShipmentChange(cs.id)}
                          />
                        }
                        label={`${cs.location} (${cs.prices.map(p => `${p.currency} ${p.price}`).join('/')})`}
                      />
                    ))}  
                  </div>
                }
              </div>
            }
          </>
        }
      </>
      }

      <FormControlLabel
        control={
          <Checkbox
            checked={hasStock}
            onChange={() => {
              if (!hasStock) onChange({target: {id: 'stock', value: 0}})
              onChange({target: {id: 'hasStock', value: !hasStock}})
            }}
          />
        }
        label={localization.get("product.has_stock")}
      />
      {hasStock &&
        <TextValidator
          style={{width: "100%"}}
          label={localization.get('product.stock')}
          name="stock"
          id="stock"
          type={"number"}
          value={stock}
          onChange={onChange}
        />
      }

      <TranslatableInputs
        children={AvailableLanguages.map(language => {
          const currentLangTranslation = translations.find(tr => tr.language === language);

          return {
            language: language,
            content: [
              <TextValidator
                id={`description${language}`}
                style={{width: "100%"}}
                label={localization.get('form.description')}
                name={`description${language}`}
                value={currentLangTranslation.description}
                onChange={e => onChangeTranslation(e.target.value, "description", language)}
              />
            ]
          }
        })}
      />
    </div>
  )
}

export default FormContent