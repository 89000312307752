import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { convertFromRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import qs from "qs";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { request } from "../../api-client";
import {
  channelPathGenerator,
  getActiveChannel,
  getActiveSection,
  getStoredUser,
  setActiveClient, setAlbumFavorites, setArtistFavorites, setChannelCanLicense,
  setPageTitle, setUseTools, slugify,
  storeActiveChannel,
  storeActiveSection,
  storeChannel,
  storeChannelCanAddToList,
  storeChannelFavoriteListName, storeClientLicencePlan, storeClientName,
  storeCurrentSection,
  storeIsDomainClient,
  translateTypes,
  userisMultiClient,
  userIsMusicSupervisor,
  userisSingleClient,
  userIsSimpleMulticlient,
  userIsSysAdmin,
  getAlbumFavorites, getArtistFavorites, getContentCreatorFavorites, getContentCreationFavorites, setContentCreatorFavorites, setContentCreationFavorites, userHasRestrictedAccess,
} from "../../api-client/core/authentication/utils";
import localization from "../../config/localization";
import {getChannelRoutes, getUrlLang, routeHasTranslation} from "../../routes/channel";
import browserHistory from "../../utils/browserHistory";
import EventManager, { events } from "../../utils/EventManager";
import CompleteRegister from "../../views/ChannelLogin/Activate/CompleteRegister";
import SetPassword from "../../views/ChannelLogin/Activate/SetPassword";
import ForgotPassword from "../../views/ChannelLogin/ForgotPassword/ForgotPassword";
import LoginChannel from "../../views/ChannelLogin/Login";
import Register from "../../views/ChannelLogin/Register";
import Album from "../Home/Album";
import Artist from "../Home/Artist";
import ContentCreator from "../Home/ContentCreator/";
import Footer from "../Home/HomeElements/Footer";
import Playlist from "../Home/Playlists/HomePlaylist";
import AuthorizationCode from "./Authorization/Code.jsx";
import Channel from "./Channel";
import ErrorPage from "./ErrorPage";
import NotFoundPage from "./ErrorPage/NotFoundPage";
import ChannelNavbar from "./Navbar";
import {get} from "lodash";
import analyticsInstance from "../../analytics/analyticsInstance";
import TagManager from "react-gtm-module";
import ContentCreationPlayer from "../../components/Letflow/NewContentCreationPlayer";
import GlobalSnackbar from "../../components/Letflow/Snackbar/GlobalSnackbar";
import EditContentCreationDialog from "../../components/Letflow/EditContentCreationDialog";
import GlobalRedirectLoginConfirmationDialog from "../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import Contact from "../Home/Contact";
import AdDialog from "../../components/Letflow/Ads/AdDialog";

const excludeNavbarAndFooterRoutes = ['login', 'register', 'password/forgot', 'set-password', 'error-page', 'public/content_creators'];

const clientInUrlIsValid = (clientId) => {
  const currentUser = getStoredUser();
  if (userIsSysAdmin()) return true

  if (userisSingleClient() || userisMultiClient() || userIsSimpleMulticlient()) {
    if (currentUser.clientRoles.some(cr => cr.client.id == clientId)) {
      return true;
    }
  }

  return false;
};

const getIdFromUrl = () => {
  let clientData = window.location.pathname.split('/')[2]

  return Number(clientData && clientData.split("-")[0])
}

const getSectionIdFromUrl = (pathname = window.location.pathname) => {
  if (! pathname.includes('/home/')) return false;
  let homeData = pathname.split('/home/')[1]

  return Number(homeData && homeData.split("-")[0])
}
const getSectionNameFromUrl = () => {
  if (! window.location.pathname.includes('/home/')) return false;
  let homeData = window.location.pathname.split('/home/')[1]

  return homeData && homeData.split("-")[1].split('/')[0];
}

class ChannelRouting extends React.Component {
  state = {
    channel: null,
    notNavbar: false,
    channelElements: [],
    hasDomain: false,
    loaded: false,
    showModal: false,
    accessDenied: false,
    limitReached: false,
    showAbout: false,
    origin: "",
    changingSection: false,
    navbarElements: [],
    subNavbarElement: null,
    subNavbarElements: [],
    showContentCreationDialog: false,
    currentPlayingContent: null,
    materialList: [],
    hasPrev: false,
    hasNext: false,
    firstElements: [],
    footerElements: [],
    editContentCreationDialogOpen: false,
    editingContentCreation: null,
    albumChannelFavorites: [],
    artistsFavorites: [],
    contentCreatorsFavorites: [],
    contentCreationsFavorites: [],
    playlistsFavorites: [],
    adTarget: null
  };

  redirectToMainLanguageIfRequired = (channel) => {
    let availableLanguages = channel.languages.map(lang => lang.language);
    let currentUrl = `${window.location.pathname}${window.location.search}`;
    currentUrl = currentUrl.startsWith('/') ? currentUrl.slice(1,currentUrl.length) : currentUrl;

    let splitted = currentUrl.split('/');
    if(splitted.length > 0 && splitted[0] == 'channel') splitted = splitted.slice(2,splitted.length);

    let currentLang;
    let relativePath = splitted.join('/');

    //check if route not has lang path, then default "es"
    if (!splitted[0] || splitted[0] === "" || splitted[0].length > 2) {
      currentLang = "es";
    }else {
      currentLang = splitted[0];
    }

    if (!availableLanguages.includes(currentLang)) {
      if (routeHasTranslation(`/${relativePath}`)) {
        if (splitted.length > 0 && availableLanguages.includes(splitted[0])) {
          splitted = splitted.slice(1,splitted.length);
        }
        if(splitted.length > 0 && splitted[0] == 'home') {
          splitted = splitted.slice(2,splitted.length);
        }
        relativePath = splitted.join('/');

        localStorage.setItem("default_language", channel.language)
        localization.setLanguage(channel.language);
        browserHistory.push(channelPathGenerator(relativePath));
        window.location.reload();
      }
    }
  }

  initTracking = (channel) => {
    analyticsInstance.setUserProperties();
    if(channel.google_tag_manager_id) {
      TagManager.initialize({gtmId: channel.google_tag_manager_id})
    }
  }

  componentDidMount = async () => {
    if (window.customScripts && window.customScripts !== "{{CUSTOM_SCRIPTS}}") {
      const script = document.createElement("script");

      script.src = window.customScripts;
      script.async = true;

      document.body.appendChild(script);
    }

    const noCache = window.location.search.includes("?no_cache=true")
  
    if(window.domainTargetVisibility === "public" && !noCache) {
      this.getMetadataByJson()
        .then(jsonData => {
          const channel = jsonData.currentChannel;
          const channelElements = jsonData.homeElements;
          const firstElements = jsonData.firstElements;

          this.configureChannel(channel, true);
          this.setHomeElements(channelElements, firstElements);
        })
        .catch((e) => {
          console.log("Error loading channel Json", e)
          this.getChannel(getActiveChannel(), true)
        })
        .finally(() => {
          this.getFavorites()

          if(getStoredUser() && 
          get(getStoredUser(), 'contentCreators', []).length > 0 && 
          get(this.state.channel, "community.preferences.redirect_to", "home") == "profile") 
          {
            browserHistory.push(channelPathGenerator("profile/general-information"))
          }
        })
    }else {
      this.getChannel(getActiveChannel() || getIdFromUrl(), !!getActiveChannel(), noCache)
      .finally(() => {
        this.getFavorites()
        if( 
          getStoredUser() && 
          get(getStoredUser(), 'contentCreators', []).length == 0 && 
          get(this.state.channel, "community.preferences.redirect_to", "home") == "profile"
        ) {
          browserHistory.push(channelPathGenerator("profile/general-information"))
        }
      })
    }

    this.checkIfNavbarIsTransparent()
    EventManager.getInstance().subscribe(events.OPEN_CONTENT_MODAL, this.openModal)
    EventManager.getInstance().subscribe(events.OPEN_MATERIAL_MODAL, this.openMaterialPlayerModal)
    EventManager.getInstance().subscribe(events.OPEN_VIEWS_LIMIT_MODAL, this.openViewsLimitModal)
    EventManager.getInstance().subscribe(events.OPEN_EDITING_MODAL, this.openEditingMaterialModal)
    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToMyList)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromMyList)
    EventManager.getInstance().subscribe(events.OPEN_CONTACT_DIALOG, this.openContactDialog)
    EventManager.getInstance().subscribe(events.OPEN_AD_DIALOG, this.openAdDialog)

    window.onpopstate = () => {
      if (this.state.showModal) {
        this.handleClose()
      }
    };
  };


  getFavorites = () => {
    if (this.state.channel) {
      this.state.channel.type !== "content" && request.albumChannelFavorite.getAll().then((albumFavorites) => setAlbumFavorites(albumFavorites))
      this.state.channel.type !== "content" && request.artistChannelFavorite.getAll().then((artistsFavorites) => setArtistFavorites(artistsFavorites))
      this.state.channel.type !== "music" && request.contentCreatorChannelFavorite.getAll().then(contentCreatorsFavorites => setContentCreatorFavorites(contentCreatorsFavorites))
      this.state.channel.type !== "music" && request.contentCreationChannelFavorite.getAll().then(contentCreationsFavorites => setContentCreationFavorites(contentCreationsFavorites))
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.OPEN_CONTENT_MODAL, this.openModal)
    EventManager.getInstance().unsubscribe(events.OPEN_MATERIAL_MODAL, this.openMaterialPlayerModal)
    EventManager.getInstance().unsubscribe(events.OPEN_VIEWS_LIMIT_MODAL, this.openViewsLimitModal)
    EventManager.getInstance().unsubscribe(events.OPEN_EDITING_MODAL, this.openEditingMaterialModal)
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToMyList)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromMyList)
    EventManager.getInstance().unsubscribe(events.OPEN_CONTACT_DIALOG, this.openContactDialog)
    EventManager.getInstance().unsubscribe(events.OPEN_AD_DIALOG, this.openAdDialog)
  }

  addToMyList = ({id, type = 'album'}) => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: this.state.channel.login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      let favorites = getAlbumFavorites();
      let artistsFavorites = getArtistFavorites();
      let contentCreatorsFavorites = getContentCreatorFavorites();
      let contentCreationsFavorites = getContentCreationFavorites();

      let { speakersFavorites,  playlistsFavorites} = this.state
      switch (type) {
        case 'album':
          favorites.push(id)
          request.albumChannelFavorite.add(id)
          break
        case 'artist':
          artistsFavorites.push(id)
          request.artistChannelFavorite.add(id)
          break
        case 'content_creator':
          contentCreatorsFavorites.push(id)
          request.contentCreatorChannelFavorite.add(id)
          break
        case 'video_content_creation':
          contentCreationsFavorites.push(id)
          request.contentCreationFavorite.add(id)
          break
        case 'playlist':
          playlistsFavorites.push(id)
          request.playlistChannelFavorite.add(id)
          break
      }
      this.setState({
        favorites,
        speakersFavorites,
        artistsFavorites,
        playlistsFavorites,
        contentCreatorsFavorites,
        contentCreationsFavorites
      })
    }
  }

  removeFromMyList = ({id, type = 'album'}) => {

    let albumChannelFavorites = getAlbumFavorites();
    let artistsFavorites = getArtistFavorites();
    let contentCreatorsFavorites = getContentCreatorFavorites();
    let contentCreationsFavorites = getContentCreationFavorites();
    let { playlistsFavorites} = this.state

    switch (type) {
      case 'album':
        if (albumChannelFavorites.indexOf(id) > -1) {
          albumChannelFavorites.splice(albumChannelFavorites.indexOf(id), 1)
          setAlbumFavorites(albumChannelFavorites)
          request.albumChannelFavorite.remove(id)
        }
        break
      case 'artist':
        if (artistsFavorites.indexOf(id) > -1) {
          artistsFavorites.splice(artistsFavorites.indexOf(id), 1)
          setArtistFavorites(artistsFavorites)
        }
        request.artistChannelFavorite.remove(id)
        break
      case 'content_creator':
        if (contentCreatorsFavorites.indexOf(id) > -1) {
          contentCreatorsFavorites.splice(contentCreatorsFavorites.indexOf(id), 1)
          setContentCreatorFavorites(contentCreatorsFavorites)
        }
        request.contentCreatorChannelFavorite.remove(id)
        break
      case 'video_content_creation':
        if (contentCreationsFavorites.indexOf(id) > -1) {
          contentCreationsFavorites.splice(contentCreationsFavorites.indexOf(id), 1)
          setContentCreationFavorites(contentCreationsFavorites)
        }
        request.contentCreationFavorite.remove(id)
        break
      case 'playlist':
        if (playlistsFavorites.indexOf(id) > -1) {
          playlistsFavorites.splice(playlistsFavorites.indexOf(id), 1)
        }
        request.playlistChannelFavorite.remove(id)
        break
    }
    this.setState({albumChannelFavorites, artistsFavorites, contentCreatorsFavorites, contentCreationsFavorites, playlistsFavorites})
  }


  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({showModal: false})
      this.checkIfNavbarIsTransparent()
      if (prevProps.location.pathname.includes('/home/') || this.props.location.pathname.includes('/home/')) {
        const prevSectionId = getSectionIdFromUrl(prevProps.location.pathname);
        const currentSectionId = getSectionIdFromUrl();
        if ((prevProps.location.pathname.includes('/home/') && !this.props.location.pathname.includes('/home/'))||
          prevSectionId !== currentSectionId ) { //check if back to main section || change section by navigation arrows
          this.onSectionChange(currentSectionId)
        }
      }
    }
  };

  configureChannel = async (channel, isDomainChannel) => {
    const currentSection = channel.sections.find(s => (getSectionIdFromUrl() ? s.id ===getSectionIdFromUrl() : s.main === 1)) || channel.sections[0];
    storeClientName(channel.client)
    setActiveClient(channel.client.id)
    storeActiveChannel(channel);
    storeChannel(channel);
    storeIsDomainClient(isDomainChannel)
    storeCurrentSection(currentSection)
    setUseTools(channel.use_tools);
    storeChannelCanAddToList(channel.visibility !== "public" || !!getStoredUser()); 

    this.redirectToMainLanguageIfRequired(channel)
    this.setState({channel, hasDomain: isDomainChannel, client: channel.client, channelRoutes: getChannelRoutes(channel)});
    this.setStylePreferences(channel)
    this.initTracking(channel)

    if (clientInUrlIsValid(channel.client.id) || userIsMusicSupervisor()) {
      storeClientLicencePlan(channel.client)
      setChannelCanLicense(channel.client)
    } 

    this.openAdDialog({type: "sections", id: currentSection.id,  name: currentSection.name})
  }

  getMetadataByJson = () =>
   fetch(`${window.location.protocol}//${window.location.hostname}/currentChannel.json?lang=${window.currentLanguage}${getSectionIdFromUrl() ? `&section_id=${getSectionIdFromUrl()}` : ''}`)
        .then(response => response.json())


  getChannel = async (channelId, isDomainChannel, removeCache = false) => 
    request.channel.get(channelId, !isDomainChannel && window.domainTargetVisibility === 'public', removeCache)
      .then(channel => {
        this.configureChannel(channel, isDomainChannel);

        if (!isDomainChannel) {
          if (!localStorage.getItem("default_language")) {
            localStorage.setItem("default_language", channel.language)
          }
          let urlLang = getUrlLang();

          if (urlLang) {
            localization.setLanguage(urlLang);
          } else {
            localization.setLanguage(localStorage.getItem("default_language"))
          }
        }

        this.requestHomeElements(channel.id, removeCache);
      })
      .catch((e) => e.status === 403 ? 
        this.setState({accessDenied: true}) : console.log(e));

  onSectionChange = (sectionId) => this.setState({changingSection: true},
     () => {
      const section = this.state.channel.sections.find(s => s.id === sectionId || (!sectionId && s.main === 1))
      this.openAdDialog({type: "sections", id: section.id,  name: section.name})

      if(window.domainTargetVisibility === "public") {
        return this.getMetadataByJson()
        .then(jsonData => {
          const channelElements = jsonData.homeElements;
          const firstElements = jsonData.firstElements;
          this.setHomeElements(channelElements, firstElements);
        }).catch(() => this.requestHomeElements(getActiveChannel()))
      }
      return this.requestHomeElements(getActiveChannel())
    })

  requestHomeElements = (channelId, removeCache = false) =>
     request.channel.getMetadata(channelId, getSectionIdFromUrl(), removeCache)
      .then(res => this.setHomeElements(res))

  setHomeElements = (channelElements, firstElements = []) => 
    new Promise(resolve => {
      const navbarElements = channelElements.filter(element => element.type === "navbar" && !!element.visible)
      const subNavbarElement = navbarElements.find(element => element.data_source === "sub_navbar");
      const subNavbarElements = !subNavbarElement ? [] : navbarElements.filter(element => element.parent === subNavbarElement.id);
      const bannerElements = channelElements.find(element => element.type === "banner")
      const footerElements = channelElements.filter(element => element.type === "footer")

      return this.setState({
        channelElements,
        navbarElements,
        subNavbarElement,
        subNavbarElements,
        firstElements, 
        footerElements,
        bannerElements: get(bannerElements, "items.data", []),
      }, resolve)
    })
      .then(() => {
        window.scrollTo(0,0)
        if (getSectionIdFromUrl()) {
          if (!this.state.channel || !this.state.channel.sections) {
            storeActiveSection({id: getSectionIdFromUrl(), name: getSectionNameFromUrl()})
            storeCurrentSection({id: getSectionIdFromUrl(), name: getSectionNameFromUrl()})
          }else {
            storeActiveSection({id: getSectionIdFromUrl(), name: getSectionNameFromUrl()})
            storeCurrentSection(this.state.channel.sections.find(section => section.id === getSectionIdFromUrl()))
          }
        }else { // main section showing on channel home
          storeCurrentSection(this.state.channel.sections.find(s => s.main === 1) || this.state.channel.sections[0])
          storeActiveSection(false);
        }

        if (this.state.channelElements.filter(element => element.data_source === "my_list").length > 0) {
          storeChannelFavoriteListName(this.state.channelElements.filter(element => element.data_source === "my_list")[0].title);
          storeChannelCanAddToList(true);
        }
      })  
      .then(() => this.setState({loaded: true, changingSection: false}));
  

  setStylePreferences = (channel) => {
    document.documentElement.style.setProperty('--main-font', channel.preferences.main_font);
    document.documentElement.style.setProperty('--title-normal', channel.preferences.container_title.color);
    document.documentElement.style.setProperty('--title-hover', channel.preferences.container_title.color_hover || '#fff');
    document.documentElement.style.setProperty('--text-normal', channel.preferences.container_description.color);
    document.documentElement.style.setProperty('--text-hover', channel.preferences.container_description.color_hover || '#fff');
    document.documentElement.style.setProperty('--card-hover', channel.preferences.interaction_color || '#000');
    let interactionColor = channel.preferences.interaction_color ? channel.preferences.interaction_color : "rgba(0,0,0,1)";
    var match = interactionColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    let interactionColorRGB = match ? `${match[1]},${match[2]},${match[3]}` : '0,0,0';
    document.documentElement.style.setProperty('--card-hover-rgb', interactionColorRGB || '0,0,0');
    document.documentElement.style.setProperty('--main-color', channel.preferences.main_color || '#fff');
    document.documentElement.style.setProperty('--main-font-color', channel.preferences.main_font_color || '#000');
    document.documentElement.style.setProperty('--secondary-color', channel.preferences.secondary_color || '#fff');
    document.documentElement.style.setProperty('--secondary-font-color', channel.preferences.secondary_font_color || '#000');
    document.documentElement.style.setProperty('--footer-color', channel.preferences.footer_color || '#1E1E1E');
    document.documentElement.style.setProperty('--footer-font-color', channel.preferences.footer_font_color || 'darkgrey');
    document.documentElement.style.setProperty('--navbar-button-text-size', channel.preferences.navbar_button_size ? channel.preferences.navbar_button_size+"px" : '16px');
    document.documentElement.style.setProperty('--navbar-text-color', channel.preferences.navbar ? channel.preferences.navbar.font_color :  "rgb(0,0,0, 1)");
    let navbarBorderColor = (channel.preferences.navbar ? channel.preferences.navbar.font_color :  "rgb(0,0,0, 1)").split(',');
    document.documentElement.style.setProperty('--navbar-border-color', [navbarBorderColor[0],navbarBorderColor[1],navbarBorderColor[2], " .5)"].toString());
    document.documentElement.style.setProperty('--navbar-shadow', get(channel, "preferences.navbar.shadow", "0 1px 10px 0 rgba(0,0,0,.1)"))

    document.documentElement.style.setProperty('--sub-navbar-background-color', get(channel, "preferences.sub_navbar.background_color", "rgb(255,255,255,1)"));
    document.documentElement.style.setProperty('--sub-navbar-font-color', get(channel, "preferences.sub_navbar.font_color", "rgb(0,0,0,1)"));
    document.documentElement.style.setProperty('--home-padding', `0px ${get(channel, "preferences.home_padding", 70)}px`)
    document.documentElement.style.setProperty('--sidebar-font-color', get(channel, "preferences.navbar.sidebar.font_color", "rgba(0,0,0,1)"))
    document.documentElement.style.setProperty('--sidebar-background-color', get(channel, "preferences.navbar.sidebar.background_color", "rgb(255,255,255,1)"))
    document.documentElement.style.setProperty('--sidebar-button-font-color', get(channel, "preferences.navbar.sidebar.buttons.font_color", "rgb(255,255,255,1)"))
    document.documentElement.style.setProperty('--sidebar-button-background-color', get(channel, "preferences.navbar.sidebar.buttons.background_color", "rgba(0,0,0,1)"))
    document.documentElement.style.setProperty('--sidebar-selector-font-color', get(channel, "preferences.navbar.sidebar.selectors.font_color", "rgb(255,255,255,1)"))
    document.documentElement.style.setProperty('--sidebar-selector-background-color', get(channel, "preferences.navbar.sidebar.selectors.background_color", "rgba(0,0,0,1)"))
    
  }

  checkIfNavbarIsTransparent = () =>
    this.setState({
      notNavbar: (
        excludeNavbarAndFooterRoutes.some(transparentRoute => this.props.location.pathname.indexOf(transparentRoute) > -1)
      )
    });

  Channel = props => 
    <Channel
      showModal={this.state.showModal}
      channelElements={this.state.channelElements.filter(element => !["navbar", "footer"].includes(element.type))}
      firstElements={this.state.firstElements}
      channel={this.state.channel}
      showAbout={this.state.showAbout}
      bannerElements={this.state.bannerElements}
      {...props}
    />
  Login = props => <LoginChannel channel={this.state.channel} publicChannel={true} {...props}/>
  Register = props => <Register channel={this.state.channel} publicChannel={true} {...props}/>
  CompleteRegister = props => <CompleteRegister channel={this.state.channel} publicChannel={true} {...props}/>
  ForgotPassword = props => <ForgotPassword channel={this.state.channel} publicChannel={true} {...props}/>
  SetPassword = props => <SetPassword channel={this.state.channel} publicChannel={true} {...props}/>
  AuthorizationCode = (props) => <AuthorizationCode channel={this.state.channel} {...props}/>
  ErrorPage = (props) => <ErrorPage channel={this.state.channel} {...props}/>
  NotFoundPage = (props) => <NotFoundPage channel={this.state.channel} {...props}/>

  userHasAccess = () => {
    const channel = this.state.channel;
    const currentUser = getStoredUser();

    if (!userIsSysAdmin()) {
      if (!currentUser.channels.some(cr => cr.channel_id == channel.id) ||
        !currentUser.clientRoles.some(cr => cr.client.id == channel.client_id)) {
        return false;
      }
    }

    return true;
  };

  parseRouting = (path, lang=null) => {
    let pathname = path
    
    if(getActiveSection()) pathname = `/home/${getActiveSection().id}-${slugify(getActiveSection().name).replace(/-/g, '_')}` + pathname
    pathname = (lang && lang !== 'es') ? `/${lang}${pathname}` : pathname;
    pathname = this.state.hasDomain ? pathname : '/channel/:channelId-:channelName' + pathname;
    
    return pathname;
  }

  openMaterialPlayerModal = data => {
    let queryString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      depth: 2,
      plainObjects: true,
    })

    queryString = {...queryString, material: data.id}

    browserHistory.push({
      search: qs.stringify(queryString),
    });

    return request.contentCreation.get(data.id, "include=translations;video.thumbnail;contentCreator.type;audio;binary;image;embededContent;tags")
      .then(response => {
        let hasPrev = false;
        let hasNext = false;

        
        if (get(data, "materialList", []).length > 0) {
          const currIndex = data.materialList.find(e => data.id === e.id).index
          
          hasPrev = currIndex > 0 ;
          hasNext = currIndex < (data.materialList.length - 1);
        }
        
        return this.setState({
          showContentCreationDialog: true,
          currentPlayingContent: response,
          materialList: data.materialList,
          hasPrev,
          hasNext
        })
      })
      .catch(() => GlobalSnackbar.showGenericError)
  }

  openModal = data => {
    let queryString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      depth: 2,
      plainObjects: true,
    })
    queryString = {...queryString, [translateTypes(data.dataType)]: `${data.id}-${slugify(data.dataName)}`}

    browserHistory.push({
      search: qs.stringify(queryString),
    });

    this.setState({
      modalEntityId: data.id,
      entityType: translateTypes(data.dataType),
      showModal: true,
      onTagChange: data.onTagChange || false,
      origin: data.origin || ""
    })
  }

  openViewsLimitModal = () => this.setState({limitReached: true})
  openContactDialog = () => this.setState({showContactDialog: true})
  openAdDialog = data => {
    if (get(this.state.channel, "ads", []).length > 0) {
      this.setState({adTarget: data})
    }
  }
  openEditingMaterialModal = data => 
  {
    console.log(data)
      return this.setState({
      editContentCreationDialogOpen: true,
      editingContentCreation: data,
    })
  }

  replaceUrlParameters = () => {
    const queryString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      depth: 2,
      plainObjects: true,
    })

    delete queryString.artista
    delete queryString.album
    delete queryString.contenido
    delete queryString.playlist
    delete queryString.material

    browserHistory.push({
      search: qs.stringify(queryString),
    });
  }


  handleClose = () => {
    this.replaceUrlParameters()
    this.setState({showModal: false, limitReached: false, showModalFooter: false, showContentCreationDialog: false, currentPlayingContent: null})
    setPageTitle()
  }
  
  renderShowModal = () =>
    <Dialog
      open={this.state.showModal}
      onEscapeKeyDown={this.handleClose}
      fullScreen={true}
      PaperProps={{style: {backgroundColor: 'var(--main-color, white)'}}}
      disableEnforceFocus
    >
      <div style={{ overflowY: "auto" }} id="scrollableDiv">
        {this.state.entityType === 'album' &&
        <Album albumId={this.state.modalEntityId} client={this.state.client} modal={true}
               onModalClose={this.handleClose} {...this.props}/>
        }
        {this.state.entityType === "artista" &&
        <Artist artistId={this.state.modalEntityId} client={this.state.client} modal={true}
                onModalClose={this.handleClose} {...this.props}/>
        }

        {this.state.entityType === 'playlist' &&
          <Playlist playlistId={this.state.modalEntityId} client={this.state.channel.client} modal={true} onModalClose={this.handleClose} {...this.props}/>
        }
        {this.state.entityType === "contenido" &&
        <>
          <ContentCreator
            {...this.props}
            onShowModalFooter={() => this.setState({showModalFooter: true})}
            contentCreatorId={this.state.modalEntityId}
            client={this.state.client}
            modal={true}
            onModalClose={this.handleClose}
            onTagChange={this.state.onTagChange}
            origin={this.state.origin}
          />
        </>
        }
      </div>
    </Dialog>

  renderViewLimitReachedDialog = () =>
    <Dialog
      open={this.state.limitReached}
      onEscapeKeyDown={this.handleClose}
      onBackdropClick={this.handleClose}
    >
      <DialogTitle>{localization.get("content.views_limit_reached")}</DialogTitle>
      <DialogContent>{localization.get("content.views_limit_reached.description")}</DialogContent>
      <DialogActions>
        <Button onClick={() => this.handleClose()}>{localization.get('form.close')}</Button>
      </DialogActions>
    </Dialog>

  getChannelLangRoutes(lang) {
    return !!this.state.channelRoutes && this.state.channelRoutes.map((route, i) => <Route key={`${lang}-${i}`} path={this.parseRouting(route.path, lang)} render={wrap(route.component)}/>)
  }

  setCurrentPlayingContent = (direction) => {
    const currIndex = this.state.materialList.find(e => this.state.currentPlayingContent.id === e.id).index

    this.openMaterialPlayerModal({id: this.state.materialList[currIndex+direction].id, materialList: this.state.materialList})
  }

  contactDialog = () => <Dialog open={this.state.showContactDialog} onBackdropClick={() => this.setState({showContactDialog: false})} maxWidth="false">
    <div style={{width: window.innerWidth <= 800 ? "75vw" :700 }}>
      <Contact
        modal={true}
        afterSubmit={() => this.setState({showContactDialog: false})}/>
    </div>
  </Dialog>

  render = () => {
     if (this.state.accessDenied) {
       return (<ErrorPage channelId={getActiveChannel() || getIdFromUrl()} errorText={localization.get('channel.not_enough_permissions')} showButton={false}/>)
    }

    if (this.state.loaded && this.state.channel) {
      let aboutHTML;
      if (this.state.channel.about && this.state.channel.about.startsWith('{')) {
        let aboutJSON = JSON.parse(this.state.channel.about);
        let aboutState = aboutJSON && EditorState.createWithContent(convertFromRaw(aboutJSON));
        aboutHTML = aboutState && stateToHTML(aboutState.getCurrentContent())
      }
    
      let centerMode = this.state.channel ? get(this.state.channel, "preferences.navbar.center_mode", false) : false;
      let availableLanguages = this.state.channel.languages.map(lang => lang.language).filter(lang => lang !== 'es').concat(['es']);
      let mobile = window.innerWidth <= 600;
      let innerPadding = 55;

      if (this.state.subNavbarElement && this.state.subNavbarElements.length > 0) {
         innerPadding += (window.innerWidth > 992 ? 130 : 150)
      }

      if (this.state.channel.navbar_tabs && mobile) {
        innerPadding += 60;
      }

      if (centerMode) {
        innerPadding += 25;
      }
                
      return (
        <div>
          <ContentCreationPlayer
            show={this.state.showContentCreationDialog}
            onNextArrow={this.state.hasNext ? () => this.setCurrentPlayingContent(1) : null}
            onPrevArrow={this.state.hasPrev ? () => this.setCurrentPlayingContent(-1) : null}
            currentContentCreation={this.state.currentPlayingContent}
            handleClose={() => this.setState({showContentCreationDialog: false, currentPlayingContent: null}, this.replaceUrlParameters)}
            navbarColor
            noAuthorLink={userHasRestrictedAccess()}
          />
          <EditContentCreationDialog 
            open={this.state.editContentCreationDialogOpen}
            contentCreation={this.state.editingContentCreation}
            type={get(this.state.editingContentCreation, 'typeSlug')}
            onClose={() => this.setState({editContentCreationDialogOpen: false})}
          />
          {this.contactDialog()}
          {get(this.state.channel, "ads", []).length > 0 && this.state.adTarget &&
            <AdDialog adTarget={this.state.adTarget} onClose={() => this.setState({adTarget: null})}/>
          }
          {!this.state.notNavbar &&
          <ChannelNavbar
            {...this.props}
            transparentMode={this.state.transparent}
            channel={this.state.channel}
            navbarElements={this.state.navbarElements}
            subNavbarElement={this.state.subNavbarElement}
            subNavbarElements={this.state.subNavbarElements}
            channelDoesntHaveElement={this.state.channelElements.filter(element => element.type !== "navbar" && element.type !== "banner").length === 0}
            onShowAboutChange={() => this.setState({showAbout: !this.state.showAbout})}
            showAbout={this.state.showAbout}
          />}
          <div
            id="wrapper"
            style={{
              backgroundColor: "var(--main-color, white)",
              paddingTop: this.state.notNavbar ? 0 :innerPadding 
            }}
          >
            <Dialog
              open={this.state.showAbout}
              onEscapeKeyDown={() => this.setState({showAbout: false})}
              onBackdropClick={() => this.setState({showAbout: false})}
              PaperProps={{style: {backgroundColor: 'var(--main-color, white)'}}}
            >
              <DialogTitle>{this.state.channel.name}</DialogTitle>
              {aboutHTML ?
              <div
                style={{color: 'var(--main-font-color, color)', padding: 20, paddingTop: 0}}
                dangerouslySetInnerHTML={{__html: aboutHTML}}
              />
              :
              <div
                style={{color: 'var(--main-font-color, color)', padding: 20}}
                dangerouslySetInnerHTML={{__html: this.state.channel.about }}
              />
              }
            </Dialog>
            <div id="inner">
              {this.renderShowModal()}
              {this.renderViewLimitReachedDialog()}
              
              {!this.state.changingSection &&
                <Router {...this.props}>
                  <Switch>
                    <Route path={this.parseRouting("/login")} render={wrap(this.Login)}/>
                    {this.state.channel.login_type === 'default' &&
                    <Route path={this.parseRouting("/register")} render={wrap(this.Register)}/>}
                    {this.state.channel.login_type === 'default' &&
                    <Route path={this.parseRouting("/password/forgot")} render={wrap(this.ForgotPassword)}/>}
                    {this.state.channel.login_type === 'default' &&
                    <Route path={this.parseRouting("/set-password")} render={wrap(this.SetPassword)}/>}
                    {this.state.channel.login_type === 'default' &&
                    <Route path={this.parseRouting("/user/activation")} render={wrap(this.CompleteRegister)}/>}
                    {(this.state.channel.login_type === 'MiBA' || this.state.channel.dashboard_login_type === 'MiBA') &&
                    <Route path={this.parseRouting("/login-code")} render={wrap(this.AuthorizationCode)}/>}
                    {(this.state.channel.login_type === 'MiBA' || this.state.channel.dashboard_login_type === 'MiBA') &&
                    <Route path={this.parseRouting("/miba/login-code")} render={wrap(this.AuthorizationCode)}/>}
                    {(this.state.channel.login_type === 'gcba' || this.state.channel.dashboard_login_type === 'gcba') &&
                    <Route path={this.parseRouting("/gcba/login-code")} render={wrap(this.AuthorizationCode)}/>}
                    <Route path={this.parseRouting("/panel/login-code")} render={wrap(this.AuthorizationCode)}/>
                    <Route path={this.parseRouting("/panel/gcba/login-code")} render={wrap(this.AuthorizationCode)}/>
                    <Route path={this.parseRouting("/panel/miba/login-code")} render={wrap(this.AuthorizationCode)}/>
                    {availableLanguages.map(lang => this.getChannelLangRoutes(lang)).flat()}
                    {availableLanguages.map(lang => <Route exact path={this.parseRouting("/", lang)} render={wrap(this.Channel)}/>)}
                    {availableLanguages.map(lang => <Route path={this.parseRouting("/", lang)} render={wrap(this.NotFoundPage)}/>)}
                  </Switch>
                </Router>
              }
            </div>
            {!this.state.notNavbar &&
             <Footer
              {...this.props}
              channel={this.state.channel}
              footerElements={this.state.footerElements}
            />}
          </div>
        </div>
      );
    } else {
      return (<></>)
    }

  };
}

const wrap = (Comp) => props => {
  /*props.location && analyticsInstance.pageView({
    path: props.location.pathname
  })*/

  return <Comp {...props}/>
}

export default ChannelRouting;
