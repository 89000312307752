import React, {Component} from "react"
import {request} from "../../api-client"
import TotalizedRankingChart from "./TotalizedRankingChart"
import TotalizedMonthlyChart from "./TotalizedMonthlyChart"
import {ScaleLoader} from "react-spinners"
import localization from "../../config/localization";
import { round } from "lodash"

const RED = '#d88488'
const GREEN = '#82ca9d'
const BLUE = '#8884d8'
const YELLOW = '#999900'
const GOLD = '#EFD700'
const SILVER = '#d7d7d7'
const BRONZE = '#CCB699'

class Graphboard extends Component {

  state = {
    loading: true,
    countries: []
  };

  componentDidMount = () => {
    document.title = localization.get('title.dashboard') + ' - Feater'
    request.country.getAll()
      .then(countries => this.setState({countries}))
    request.graphboard
      .getAll()
      .then(response => this.setState({ ...response, loading: false }))
      .catch(e => {
        throw new Error(e);
      }
    );
  }

  render = () => {
    let {loading, data, meta} = this.state;
  

    if (loading) {
      return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '150px' }}>
          <ScaleLoader />
        </div>
    } else {
      let {from, to} = meta || {};

      return <div style={{width: "100%", float: "left", position: "relative"}}>
          <TotalizedMonthlyChart
            title={localization.get('graphboard.clients')}
            values={data['clients'].values}
            totals={data['clients'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), hideInChart: true },
              { key: 'active', name: localization.get('graphboard.active'), color: RED },
              { key: 'demos', name: localization.get('graphboard.demo'), color: BLUE }
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          <TotalizedMonthlyChart
            title={localization.get('graphboard.channels')}
            values={data['channels'].values}
            totals={data['channels'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), hideInChart: true },
              { key: 'active', name: localization.get('graphboard.active'), color: RED },
              { key: 'demos', name: localization.get('graphboard.demo'), color: BLUE }
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          <TotalizedMonthlyChart
            title={localization.get('graphboard.users')}
            values={data['users'].values}
            totals={data['users'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), hideInChart: true },
              { key: 'channel_users', name: localization.get('graphboard.channel_users'), color: BLUE },
              { key: 'users', name: localization.get('graphboard.client_users'), color: RED },
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          <TotalizedMonthlyChart
            title={localization.get('graphboard.all')}
            values={data['client_data'].values}
            totals={data['client_data'].totals}
            lines={[
              { key: 'playlists', name: localization.get('graphboard.playlists'), color: RED },
              { key: 'upload_links', name: localization.get('graphboard.links'), color: GREEN },
              { key: 'reels', name: localization.get('graphboard.reels'), color: BLUE },
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          <TotalizedMonthlyChart
            title={localization.get('graphboard.contents')}
            values={data['content_creators'].values}
            totals={data['content_creators'].totals}
            lines={[
              { key: 'editorials', name: localization.get('graphboard.editorials'), color: GREEN },
              { key: 'audiovisual', name: localization.get('graphboard.audiovisual'), color: BLUE },
              { key: 'talents', name: localization.get('graphboard.talents'), color: RED },
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          <TotalizedMonthlyChart
            title={localization.get('graphboard.materials')}
            values={data['content_creations'].values}
            totals={data['content_creations'].totals}
            lines={[
              { key: 'imageMaterials', name: localization.get('graphboard.images'), color: BLUE },
              { key: 'videoMaterials', name: localization.get('graphboard.videos'), color: RED },
              { key: 'binaryMaterials', name: localization.get('graphboard.binaries'), color: YELLOW },
              { key: 'embedMaterials', name: localization.get('graphboard.embed_content'), color: GOLD },
              { key: 'audioMaterials', name: localization.get('graphboard.audios'), color: GREEN },
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />

          <TotalizedMonthlyChart
            title={localization.get('graphboard.favorites')}
            values={data['favorites'].values}
            totals={data['favorites'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), color: GOLD, hideInChart: true },
              { key: 'contents', name: localization.get('graphboard.contents'), color: RED },
              { key: 'tracks', name: localization.get('graphboard.tracks'), color: GREEN },
              { key: 'materials', name: localization.get('graphboard.materials'), color: BLUE },
              { key: 'albums', name: localization.get('graphboard.albums'), color: YELLOW },
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />


          <TotalizedMonthlyChart
            title={localization.get('graphboard.batch_files')}
            values={data['batchFiles'].values}
            totals={data['batchFiles'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), color: GOLD, hideInChart: true },
              { key: 'active', name: localization.get('graphboard.active'), color: RED },
              { key: 'used', name: localization.get('graphboard.used'), color: GREEN },
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          
          
          <TotalizedMonthlyChart
            title={localization.get('graphboard.tracks')}
            values={data['music'].values}
            totals={data['music'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), color: BLUE, hideInChart: true},
              { key: 'active', name: localization.get('graphboard.available'), color: GREEN },
              { key: 'disbaled', name: localization.get('graphboard.disabled'), color: RED},
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
          
          <TotalizedMonthlyChart
            title={localization.get('graphboard.license_by_type')}
            values={data['licenseTypes'].values}
            totals={data['licenseTypes'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.licenses'), color: RED },
              { key: 'own_channels', name: localization.get('graphboard.own_channels'), color: GREEN },
              { key: 'commercial', name: localization.get('graphboard.commercial'), color: BLUE },
              { key: 'sadaic', name: localization.get('graphboard.sadaic'), color: YELLOW }
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />

          <TotalizedRankingChart
            title={localization.get('graphboard.track_per_dealer')}
            data={data['musicPerMusicDealer']}
            idKey="id"
            labelKey="name"
            valueKey="total"
            name={localization.get('graphboard.tracks')}
            formatter={value => { 
              const total = data['musicPerMusicDealer'].reduce((acc, curr) => acc + curr.total, 0);
              const percentage = round(value / total * 100, 0);
              
              return `${new Intl.NumberFormat('es').format(value)} (${percentage}%)`
            }}
            colors={{ 1: BRONZE, 2: SILVER, 3: GOLD, 4: GREEN, 5: BLUE }}
          /> 

          <TotalizedRankingChart
            title={localization.get('graphboard.license_per_dealer')}
            data={data['licensePerMusicDealer']}
            idKey="id"
            labelKey="name"
            valueKey="total"
            name={localization.get('licenses')}
            formatter={value => { 
              const total = data['licensePerMusicDealer'].reduce((acc, curr) => acc + curr.total, 0);
              const percentage = round(value / total * 100, 0);
              
              return `${new Intl.NumberFormat('es').format(value)} (${percentage}%)`
            }}
            colors={{ 1: BRONZE, 2: SILVER, 3: GOLD, 4: GREEN, 5: BLUE }}
          /> 
          <br/>
          <br/>
          <TotalizedRankingChart
            title={localization.get('graphboard.top_client_sizes')}
            data={data['clientsQuota']}
            idKey="id"
            labelKey="name"
            valueKey="total"
            name={"GBs"}
            formatter={value => new Intl.NumberFormat('es').format(value)}
            colors={{ 1: BRONZE, 2: SILVER, 3: GOLD, 4: GREEN, 5: BLUE }}
          /> 
          <TotalizedRankingChart
            title={localization.get('graphboard.quota_per_owner')}
            data={data['quotaPerOwner']}
            idKey="id"
            labelKey="name"
            valueKey="total"
            name={"GBs"}
            formatter={value => { 
              const total = data['quotaPerOwner'].reduce((acc, curr) => acc + curr.total, 0);
              const percentage = round(value / total * 100, 0);
              
              return `${new Intl.NumberFormat('es').format(value)} (${percentage}%)`
            }}
            colors={{ 1: BRONZE, 2: SILVER, 3: GOLD, 4: GREEN, 5: BLUE }}
          /> 

          <br/>
          <br/>
          <TotalizedRankingChart
            title={localization.get('graphboard.top_client_rekognition')}
            data={data['topRekognitionCounterPerClient']}
            idKey="id"
            labelKey="name"
            valueKey="total"
            name={"Rekognition requests"}
            formatter={value => new Intl.NumberFormat('es').format(value)}
            colors={{ 1: BRONZE, 2: SILVER, 3: GOLD, 4: GREEN, 5: BLUE }}
          /> 
          {/* <TotalizedRankingChart
            title={localization.get('graphboard.rekognition_per_owner')}
            data={data['rekognitionCounterPerOwner']}
            idKey="id"
            labelKey="name"
            valueKey="total"
            name={"Rekognition requests"}
            formatter={value => { 
              const total = data['rekognitionCounterPerOwner'].reduce((acc, curr) => acc + parseInt(curr.total || 0, 10), 0);
              const percentage = round(value / total * 100, 0);
              
              return `${new Intl.NumberFormat('es').format(value)} (${percentage}%)`
            }}
            colors={{ 1: BRONZE, 2: SILVER, 3: GOLD, 4: GREEN, 5: BLUE }}
          />  */}
          
          <TotalizedMonthlyChart
            title={localization.get('graphboard.rekognition_per_owner')}
            values={data['rekognition'].values}
            totals={data['rekognition'].totals}
            lines={[
              { key: 'total', name: localization.get('graphboard.total'), color: BLUE, hideInChart: true},
              { key: 'ttm', name: 'TTM', color: GREEN },
              { key: 'feater', name: 'Feater', color: RED},
            ]}
            formatter={value => new Intl.NumberFormat('es').format(value)}
          />
        </div>
      }
    }
}

export default Graphboard
