import React, {Component} from "react";
import {makeFitImageUrlWithSize, makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {Brush, LiveTv, Settings} from "@material-ui/icons";
import ServerSidePaginationTable from "../../../components/Letflow/Table/ServerSidePaginationTable";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import browserHistory from "../../../utils/browserHistory";
import LiveStreams from "../LiveStreams";
import {Link} from "react-router-dom";
import {addHttpsToUrl, slugify, userCanCreateClient} from "../../../api-client/core/authentication/utils";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {channelPurposes} from "../utils"


class Table extends Component {

  state = {
    total: '',
    categories: [],
    clients: [],
    showPlaylists: "all",
    type: "all",
    showLiveStreamSettings: false,
    activeChannel: '',
    channelType: "all"
  }

  componentDidMount = () => {
    request.channel.getAll().then(channels => this.setState({channels}))
    request.client.getAll().then(clients => this.setState({clients}))
  };

  handleCloseDialog = () => this.setState({showLiveStreamSettings: false, activeChannel: '', allowFakeLivestreams: false})

  renderLiveStreamsDialog = () =>
    <LiveStreams showDialog={this.state.showLiveStreamSettings} channelId={this.state.activeChannel} allowFakeLivestreams={this.state.allowFakeLivestreams} channel={this.state.activeChannelComplete} handleCloseDialog={this.handleCloseDialog}/>

  columns = () => [
    {
    id: "image",
    accessor: channel => (
      <Link
        to={"#"}
        onClick={() => {
          let url = !!channel.domain ? addHttpsToUrl(channel.domain.url) : `/channel/${channel.id}-${slugify(channel.name)}`;

          if(channel.language !== "es") url+=`/${channel.language}`

          if(channel.visibility==="public" && channel.domain) url+='?no_cache=true'

          return window.open(url, "_blank")
        }}
      >
        <div style={{backgroundColor: channel.preferences.main_color, width: 50, height: 50, display: "flex",justifyContent: "center",alignItems: "center"}}>
          {channel.faviconImage ?
            <img alt="Channel" src={makeFitImageUrlWithSize({image: channel.faviconImage}, "xs", "png")} width={50}/>
            :
            <p style={{fontSize: 24, color: channel.preferences.main_font_color, textDecoration: "uppercase", textAlign: "center"}}>{channel.name.substring(0,3)}</p>
          }
        </div>
      </Link>
    ),
    style: {
    float: "left",
    },
    resizeable: false,
    sortable: false,
    filterable: false,
    width: 60,
    },
  {
      Header: localization.get('table.name'),
      accessor: "name",
      filterAll: true,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      id: "client[name]",
      Header: localization.get("table.client"),
      accessor: "client.name",
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.client
            .getAll({
              filterBy: [{
                column: 'name',
                filter
              }]
            })
            .then(clients => clients.map(client => client.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    },

    {
      Header: localization.get("table.is_public"),
      id: "visibility",
      accessor: channel => <span>{localization.get(`channel.${channel.visibility}`)}</span>,
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          <option value="public">{localization.get('channel.open')}</option>
          <option value="registered">{localization.get('channel.registered')}</option>
          <option value="closed">{localization.get('channel.closed')}</option>
          <option value="private">{localization.get('channel.private')}</option>
        </select>,
      style: {
        textAlign: "left"
      },
      minWidth: 70,
      maxWidth: 100,
    },
    {
      id: "purpose",
      Header: localization.get('table.purpose'),
      accessor: channel => channel.purpose ? channelPurposes.find(p => p.value == channel.purpose).label : "",
      maxWidth: 110,
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          {channelPurposes.map(purpose => <option value={purpose.value}>{purpose.label}</option>)}
        </select>,
      Cell: cell => {
        return <span>{localization.get(cell.value)}</span>;
      },
    },
    {
      Header: localization.get("table.income_usd"),
      id: "allTimeEarningsUSD",
      accessor: channel => parseInt(channel.allTimeEarningsUSD),
      filterable: false,
      sortable: false,
      style: {
        textAlign: "left"
      },
      minWidth: 70,
      maxWidth: 100,
    },
    {
      Header: localization.get("table.income_ars"),
      id: "allTimeEarningsARS",
      accessor: channel => parseInt(channel.allTimeEarningsARS),
      filterable: false,
      sortable: false,
      style: {
        textAlign: "left"
      },
      minWidth: 70,
      maxWidth: 100,
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      minWidth: 180,
      accessor: channel => {
        const buttonBuilder = new TableButtonsBuilder()
        buttonBuilder.withOther(
          <TableButton title={localization.get('livestreams.schedule')} onClick={() => this.setState({showLiveStreamSettings: true, activeChannel: channel.id, allowFakeLivestreams: channel.client.allow_fake_livestreams, activeChannelComplete: channel})}>
            <LiveTv />
          </TableButton>
        )
        buttonBuilder.withOther(
          <TableButton title={localization.get('channel.editor')} onClick={() => browserHistory.push(`/panel/channels/${channel.id}/editor`)}>
            <Settings/>
          </TableButton>
        )
        buttonBuilder.withOther(
          <TableButton title={localization.get('channel.customization')} onClick={() => browserHistory.push(`/panel/channels/${channel.id}/preferences`)}>
            <Brush />
          </TableButton>
        )
        buttonBuilder.withEdit(makeUrlWithTableParams(`/panel/channels/${channel.id}/edit`))

        userCanCreateClient() && buttonBuilder.withDelete(
          channel.name,
          () => request.channel.delete(channel.id),
          state => {
            if (state === "passed") {
              this.refs.table.removeById(channel.id)
            }
          }, true
        )
        return buttonBuilder.build()
      },

      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }

  ]


  render = () => {
    return (
      <React.Fragment>
        {this.renderLiveStreamsDialog()}
        <div style={{ width: "100%", float: "left", position: "relative", display: "flex", flexDirection: "column" }}>
          <FormControl style={{width: "100%", float: "left", position: "relative"}}>
            <RadioGroup
              name="showClients"
              value={this.state.channelType}
              onChange={event => {
                this.setState({ channelType: event.target.value })
                this.refs.table.forceUpdate();
              }}
              style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('clients.all')} />
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="0" control={<Radio color="default" />} label={localization.get('clients.active')} />
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="1" control={<Radio color="default" />} label={localization.get('demo')} />
            </RadioGroup>
          </FormControl>
          <ServerSidePaginationTable
            requestTableData={paginationConfig => {
              if (this.state.selectedClient) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'client_id', filter: this.state.selectedClient }]) }
              }
              if (this.state.type !== "all") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'type', filter: this.state.type }]) }
              }

              if (this.state.channelType !== "all") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'demo', filter: this.state.channelType === "1" ? 1: 0 }]) }
              }

              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'for_dashboard', filter: 1}]) }

              return request.channel.getAll(paginationConfig, "include=client;domain;faviconImage;allTimeEarningsUSD;allTimeEarningsARS").then((response) => {
                  this.setState({ total: response.meta.total })
                  return response
                })
            }}
            ref="table"
            columns={this.columns()}
          />
        </div>
      </React.Fragment>
    );
  }


}

export default Table;
