import React from "react";
import 'shaka-player/dist/controls.css';
import './ShakaPlayer.css';
import {
  getMainTranslation,
  getStoredChannel
} from "../../../api-client/core/authentication/utils";
import {get} from 'lodash';
import {request} from "../../../api-client";
import {isNullOrUndefined} from "util";
import {isIOSDevice, isIpadOSDevice, isMobileDevice, isSafari} from "../../../utils";
import moment from "moment";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { PlayArrow, SkipNext } from "@material-ui/icons";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import { clearInterval, setInterval } from "timers";
import localization from "../../../config/localization";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {onImageError} from '../../../layouts/Home/HomeElements/utils';

const shaka = require('shaka-player/dist/shaka-player.ui.js');

class ShakaPlayer extends React.PureComponent{

  constructor(props) {
    super(props);
    this.state = {
      trackedPlay: false,
      autoPlay: props.autoPlay,
      preRollVideo: props.preRollVideo,
      showSkip: false,
      currentTime: 0,
    }

    this.preRollVideoRef = React.createRef();
    this.preRollVideoContainerRef = React.createRef();

    this.videoRef = React.createRef();
    this.videoContainerRef = React.createRef();

    this.onErrorEvent = this.onErrorEvent.bind(this);
    this.onError = this.onError.bind(this);

    this.castReceiverId = process.env.REACT_APP_CAST_RECEIVER_ID || "930DEB06";
    this.castProxy = null;

    this.player = null;
    this.ui = null;
  }


  componentDidMount = async () => {
    this.pauseBanners()

    const {preRollVideo, video} = this.props;

    if (preRollVideo && (preRollVideo.hls && preRollVideo.dash)) {
      await this.initPlayer({
        video: preRollVideo,
        isPreRoll: true,
        videoRef: this.preRollVideoRef,
        videoContainerRef: this.preRollVideoContainerRef
      });
    } else {
      await this.initPlayer({video});

      const castProxy = this.castProxy;
      try {
        if (castProxy && castProxy.isCasting()) {
          castProxy.cast();
        }
      } catch (error) {
        this.onError(error);
      }
    }

    // Asegurarse de que el cálculo de la altura se realice después de montar el componente
    if (this.videoContainerRef) {
      this.setState({ containerHeight: this.videoContainerRef.offsetWidth / 16 * 9 });
    }
  }

  handleTimeUpdate = () => {
    const currentTime = Math.floor(this.videoRef.currentTime);
    if (this.state.currentTime !== currentTime && currentTime % 30 === 0 && currentTime > 0) {
      this.setState({currentTime}, () => analyticsInstance.videoTracking({
        name: getMainTranslation(this.props.material, "title"),
        ...(this.props.material.contentCreator ? {content_name: getMainTranslation(this.props.material.contentCreator, "name")} : {}),
        content_type: "Video",
        seconds_viewed: currentTime,
      }))
    }
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.player) {
      this.player.destroy();
    }

    if (this.videoContainerRef) {
      this.setState({height: this.videoContainerRef.offsetWidth / 16 * 9}); 
    }

    this.props.material && this.videoRef.removeEventListener('timeupdate', this.handleTimeUpdate);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.muted !== this.props.muted) {
      this.onMuteClick(nextProps.muted)
    }
  }

  pauseBanners = () => {
    const bannerVideo = document.getElementById('banner-video');
    if (bannerVideo) {
      bannerVideo.pause();
    }
  }

  onError = (error) => {
    if(this.props.onError) this.props.onError();

    console.error('Error code', error.code, 'object', error);
  }

  onErrorEvent = (event) => this.onError(event.detail);
  getManifestUri = video => {
    if (video.hls && (isSafari() || isIOSDevice() || isIpadOSDevice())) {
      return video.hls;
    } else if (video.dash) {
      return  video.dash;
    } else if (video.url) {
      return  video.url+"?shaka=true";
    }

      return "";
  }


  initPlayer = async ({
    video,
    isPreRoll = false,
    videoRef = this.videoRef,
    videoContainerRef = this.videoContainerRef
  }) => {
    shaka.polyfill.installAll();

    let player = new shaka.Player(videoRef);

    player.configure({
      streaming: {
        bufferingGoal: 30,
      },
      playback: {
        playsinline: true,
        inline: true
      }
    });

    //DRM
    if (video.drm) {
      let drm = get(video, 'drm');

      const expiredAt = moment(get(video, 'drm.expires_at'));
      const playedAt = moment();

      if (playedAt.isAfter(expiredAt)) {
        const {reloadDrm} = this.props;
        if (reloadDrm) {
          drm = await reloadDrm();
        } else {
          window.location.reload(false);
        }
      }

      player.configure({
        drm: {
          servers: {
            'com.widevine.alpha': get(drm, 'widevine'),
            'com.microsoft.playready': get(drm, 'playready'),
            'com.apple.fps': get(drm, 'fairplay')
          },
          advanced: {
            'com.apple.fps': {
              'serverCertificateUri': '/static/fps/fairplay.cer'
            }
          }
        }
      });
    }

    player.addEventListener('error', this.onErrorEvent);

    // UI
    const uiConfig = this.configUIButtons(isPreRoll)

    const ui = new shaka.ui.Overlay(player, videoContainerRef, videoRef);

    ui.configure(uiConfig);

    this.ui = ui;

    const {autoPlay} = this.state

    try {
      let manifestUri = this.getManifestUri(video);

      await player.load(manifestUri);
      this.player = player;
      if (autoPlay) {
        let video = this.videoRef
        video.play();
      }

    } catch (error) {
      this.onError(error);
    } finally {
      if (!isPreRoll) {
        const castProxy = new shaka.cast.CastProxy(videoRef, player, this.castReceiverId);
        this.castProxy = castProxy;
        window.castProxy = castProxy;
      }
    }
  };

  configUIButtons = (isPreRoll) => {
    if (!isNullOrUndefined(this.props.controls) && !this.props.controls) {
      return {
        addSeekBar: false,
        addBigPlayButton: false,
        controlPanelElements: []
      };
    }


    const uiConfig = {
      addBigPlayButton: true,
    };
    if (isPreRoll) {
      uiConfig['controlPanelElements'] = ['mute', 'volume', 'time_and_duration'];
      uiConfig['addSeekBar'] = false;
      uiConfig['doubleClickForFullscreen'] = false;
      uiConfig['enableKeyboardPlaybackControls'] = false;
    } else {
      uiConfig['controlPanelElements'] = ['mute', 'volume', 'time_and_duration', 'fullscreen', 'cast', 'airplay', 'overflow_menu'];
      uiConfig['overflowMenuButtons'] = [ 'captions', 'quality', 'picture_in_picture', 'playback_rate'];
      uiConfig['addSeekBar'] = true;
      uiConfig['seekOnTaps'] = true;
      uiConfig['castReceiverAppId'] = this.castReceiverId;
    }

    if (isMobileDevice()) {
      uiConfig['doubleClickForFullscreen'] = false;
      uiConfig['enableFullscreenOnRotation'] = true;
    }

    return uiConfig;
  }

  onPlay = () => {
    const {
      mainMaterialTracked,
      contentCreationId,
      preview,
      timeToTrack,
      videoTitle,
      trackedMainMaterialPlayed,
      setAutoPlay,
      limited,
    } = this.props;

    // if (isMobileDevice() && this.videoRef.clientWidth < window.screen.width) {
    //   document.getElementsByClassName('shaka-fullscreen-button')[0].click();
    // }

    const {trackedPlay} = this.state;

    if (!trackedPlay && this.props.material) {
      setAutoPlay(true);
      // GAevent(`Contenido${trackingTag > 0 ? `-${trackingTag}` : ""} seccion:${getCurrentSection().id} origen:${this.props.origen}`, "Play", videoTitle, trackingTag);

      if (!!this.props.material) {
        analyticsInstance.playContent({
          name: getMainTranslation(this.props.material, "title"),
          type: "Video",
          content: this.props.material.contentCreator && getMainTranslation(this.props.material.contentCreator, "name")
        })
      }
      this.setState({trackedPlay: true});
    }

    if (!mainMaterialTracked && contentCreationId && limited && isNullOrUndefined(preview)) {
      this.interval = setInterval(() => {
          !mainMaterialTracked && request.contentCreation.trackView(contentCreationId, this.props.trackingToken)
            .then(() => this.setState({sentView: true}))
            .finally(() => {
              trackedMainMaterialPlayed();
              clearInterval(this.interval);
            })
        }, timeToTrack * 1000);
    }

    this.props.material && this.videoRef.addEventListener('timeupdate', this.handleTimeUpdate);
  }


  onMuteClick = (mute) => {
    let video = this.videoRef
    if (! video) return;
    if (mute) {
      video.muted = true
      video.pause()
    }else {
      video.play()
      video.muted = false
    }
  }

  onPreRollVideoEnded = async () => {
    const {video, setShowPreRoll} = this.props;

    if (setShowPreRoll) {
      setShowPreRoll(false);
    }

    await this.ui.getControls().destroy();

    this.setState({
      preRollVideo: false,
      autoPlay: true
    }, async () => {
      await this.initPlayer({video});
    });
    if (this.preRollTimePoll) clearInterval(this.preRollTimePoll);
  }

  handleSpeedChange = (speed) => {
    if (this.player) {
      this.player.configure({ streaming: { playbackRate: speed } });
    }
  };


  renderSpeedButtons = () => {
    const speeds = [1, 1.5, 2];

    return speeds.map((speed) => (
      <button key={speed} onClick={() => this.handleSpeedChange(speed)}>
        {speed}x
      </button>
    ));
  };


  render = () => {
    let {thumbnail, subtitleFontSize, subtitleMargin, muted, blur=false} = this.props;
    const {preRollVideo, containerHeight} = this.state;

    if (thumbnail) {
      thumbnail = `${thumbnail}${thumbnail.includes('?') ? '&' : '?'}origin=shaka`;
    }

    if (preRollVideo && preRollVideo.dash && preRollVideo.hls) {
      if (getStoredChannel() && getStoredChannel().skip_time_pre_roll) {
        this.preRollTimePoll = this.preRollTimePoll || setInterval(() => {
          let time = this.preRollVideoRef && this.preRollVideoRef.currentTime;
          if (!this.state.showSkip && time > getStoredChannel().skip_time_pre_roll) {
            this.setState({showSkip: true});
          }
        }, 1000);
      }
      return (
        <div
          ref={ref => this.preRollVideoContainerRef = ref}
          style={this.props.styles}
          className={blur ? "shaka-player-blurred-container" : ""}
        >
          <video
            playsInline={this.props.isBanner}
            className="shaka-video"
            ref={ref => this.preRollVideoRef = ref}
            poster={thumbnail}
            crossOrigin="anonymous"
            onContextMenuCapture={(event) => {event.preventDefault(); return false}}
            onEnded={this.onPreRollVideoEnded}
            autoplay={this.props.autoPlay}
          >
          </video>
          {this.preRollVideoRef && this.state.showSkip && <button style={{position: "absolute", zIndex: 2, top: 20, right: 20, cursor: "pointer", display: 'flex', alignItems: 'center'}} onClick={this.onPreRollVideoEnded}>{localization.get("creation.skip_preroll")} <SkipNext/></button>}
        </div>
      )
    }

    return(
      <CustomSubtitlesContainer
        fontSize={subtitleFontSize}
        margin={subtitleMargin}
        ref={ref => this.videoContainerRef = ref}
        style={{...this.props.styles, height: containerHeight ? `${containerHeight}px` : 'auto'}}
      >
        <div className="shaka-video-container">
          <video
            playsInline={this.props.isBanner}
            webkit-playsinline={this.props.isBanner}
            x5-playsinline={this.props.isBanner}
            x5-video-player-type={this.props.isBanner ? "h5" : undefined}
            x5-video-player-fullscreen={this.props.isBanner ? "false" : undefined}
            className="shaka-video"
            muted={muted}
            loop={this.props.loop}
            ref={ref => this.videoRef = ref}
            poster={thumbnail}
            crossOrigin="anonymous"
            onContextMenuCapture={(event) => {event.preventDefault(); return false}}
            onPlay={this.onPlay}
            autoplay={this.props.autoPlay}
          />
        </div>
      </CustomSubtitlesContainer>
    );
  }
}
const CustomSubtitlesContainer = styled.div`
  .shaka-text-container span {
    font-size: ${props => props.fontSize ? props.fontSize : 20}px!important;
    margin-bottom: ${props => props.margin ? props.margin : 10}px!important;
  }
`;
 
class ShakaPlayerThumbnailWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      height: null,
    }
  }


  componentDidMount = () => {
    if (this.thumbnailRef) {
      this.setState({height: this.thumbnailRef.offsetWidth / 16 * 9}); 
    }
  }

  render = () => {
    let {showThumbnail=false, thumbnail, autoPlay, bgColor, width, playOnHover=false, blur=false} = this.props;
    let {showVideo, height} = this.state;
    let shakaPlayer = <ShakaPlayer {...this.props} autoPlay={showThumbnail || autoPlay} thumbnail={showThumbnail ? null : this.props.thumbnail} material={this.props.material} />;
    if (thumbnail) {
      thumbnail = `${thumbnail}${thumbnail.includes('?') ? '&' : '?'}origin=shaka`;
    }

    playOnHover = window.innerWidth > 600 && !blur ? playOnHover : false;
    
    if (showThumbnail && thumbnail && !showVideo) {
      return (
        <div
        style={{...this.props.styles, width: width || '100%', minWidth: width || '100%', height: height || 'auto', minHeight: height, backgroundColor: bgColor, position: 'relative', float: 'left', display: 'flex', alignItems: "center", justifyContent: 'center'}}
        ref={ref => this.thumbnailRef = ref}
        className={blur ? "shaka-player-blurred-container" : ""}
        onMouseEnter={() => playOnHover && this.setState({showVideo: true})}  
      >
        <img src={thumbnail} onError={onImageError} style={{maxHeight: '100%', maxWidth: '100%', position: 'relative', zIndex: 2}} onLoad={() => setTimeout(() => this.setState({imgReady: true}), 100)} />
        <IconButton
          onClick={() => this.setState({showVideo: true})}
          className="shaka-player-wrapper-play-button"
          style={{zIndex: 3, opacity: this.state.imgReady ? 1 : 0, transition: "opacity .2s ease-out"}}
        >
          <PlayArrow className="shaka-player-wrapper-play-icon"/>
        </IconButton>
        {!this.state.imgReady &&
          <img style={{position: 'absolute', width: 100, height: 100, top: 'calc(50% - 50px)', left: 'calc(50% - 50px)', zIndex: 1}} src={LoadingSpinner} alt="Loading logo"/>
        }
      </div>
      )
    } 
    return shakaPlayer;
  }
}

export default ShakaPlayerThumbnailWrapper